@import "Css/Colors.scss";

.export-confirm-popup {
  width: 414px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.header {
  background-color: $neutral_10;
  padding: 0 25px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: $title_color;
  display: flex;
  align-items: center;
}

.header-close {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  padding: 0;
  cursor: pointer;
}

.content {
  padding: 18px 47px 32px 36px;
}

.text {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $neutral_hover_30;
  margin-bottom: 44px;
}

.content-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btns {
  display: flex;
}

.btn {
  height: 35px !important;
  padding: 0 10px !important;
  border-radius: 4px !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 18px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.btn--submit {
  margin-left: 5px;

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1) !important;
  color: white !important;
  background-color: $brand_primary_color !important;
}
