@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
body {
  background-color: #e5e5e5 !important; }

.ant-btn {
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 1 !important;
  padding: 5px 34px !important;
  display: flex;
  align-items: center; }

.toolbar-btn-wrapper .ant-btn {
  color: #424d55;
  font-size: 14px !important;
  line-height: 18px !important;
  padding: 0 !important;
  width: 100%;
  height: 28px;
  border: none;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .toolbar-btn-wrapper .ant-btn:hover, .toolbar-btn-wrapper .ant-btn:active, .toolbar-btn-wrapper .ant-btn:focus {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    color: white;
    background-color: #0e8ce6; }
  .toolbar-btn-wrapper .ant-btn.ant-btn-primary, .toolbar-btn-wrapper .ant-btn.ant-btn-primary[disabled] {
    background-color: #0e8ce6 !important;
    color: white !important;
    border: none; }

.ant-btn-primary {
  background: #0e8ce6 !important; }
  .ant-btn-primary:hover {
    background: #1378c0 !important; }

.ant-btn-default {
  background: #b7ddf7 !important;
  border-color: #b7ddf7 !important;
  color: #fff !important; }

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
  color: #fff;
  background-color: #b7ddf7 !important; }

.ant-radio-inner:after {
  left: 4px;
  top: 4px; }

.ant-radio-wrapper {
  display: block;
  font-size: 16px !important; }

.ant-collapse {
  background: #fff;
  box-sizing: border-box;
  border: none !important;
  width: 168px !important; }
  .ant-collapse > .ant-collapse-item {
    border-top: none;
    border-radius: 4px !important;
    text-align: left;
    border-bottom: none !important; }
  .ant-collapse .ant-collapse-item-active {
    border-bottom: none !important; }
  .ant-collapse .ant-collapse-content-box {
    border: none; }
  .ant-collapse .ant-collapse-content {
    overflow: visible;
    overflow: initial;
    border-top: none;
    padding-bottom: 14px; }
  .ant-collapse .ant-collapse-header {
    color: #424d55 !important;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-bottom: none !important;
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    line-height: 14px !important;
    padding: 16px 0 16px 0 !important;
    border-top: 1px solid #dae1e6 !important; }
    .ant-collapse .ant-collapse-header svg {
      width: 12px;
      height: 10px;
      fill: #424d55;
      margin-right: 11px; }
      .ant-collapse .ant-collapse-header svg * {
        fill: #424d55; }
    .ant-collapse .ant-collapse-header label {
      margin: 0; }
  .ant-collapse .collapse-childrens {
    width: 153px !important; }
    .ant-collapse .collapse-childrens .ant-collapse-header {
      border-top: none !important; }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 15px;
    padding-right: 0; }
  .ant-collapse .panel-header {
    display: flex;
    justify-content: space-between !important;
    flex-direction: row;
    align-items: center;
    background-color: #fff; }
  .ant-collapse .ant-radio-button-wrapper {
    margin-right: 8px;
    border-radius: 4px;
    background: #fff;
    padding: 0 20px;
    border: 1px solid #dae1e6;
    color: #96a2aa; }
  .ant-collapse .ant-radio-button-wrapper-checked {
    border: 1px solid #0e8ce6;
    color: #0e8ce6;
    box-shadow: none; }

.ant-select {
  color: #96a2aa; }
  .ant-select .ant-select-selection,
  .ant-select .ant-select-selection__rendered {
    height: 100%;
    background-color: inherit; }
    .ant-select .ant-select-selection .ant-select-selection-selected-value,
    .ant-select .ant-select-selection__rendered .ant-select-selection-selected-value {
      display: flex !important;
      align-items: center; }
  .ant-select .ant-select-selection__rendered {
    line-height: 39px;
    color: #424d55;
    padding-right: 25px; }
  .ant-select .ant-select-arrow {
    margin-top: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    .ant-select .ant-select-arrow .anticon.anticon-down.ant-select-arrow-icon {
      width: 10px;
      height: 10px;
      background-image: url(/static/media/arrowDown.20a878b2.svg);
      background-repeat: no-repeat;
      background-position: center; }
    .ant-select .ant-select-arrow svg {
      display: none !important; }
  .ant-select .anticon.anticon-down.ant-select-arrow-icon svg {
    position: absolute;
    right: 2px;
    border-radius: 0.4px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #96a2aa; }
  .ant-select .select-close {
    display: block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    opacity: 0.5; }
    .ant-select .select-close:hover {
      opacity: 1; }
  .ant-select .ant-select-open .ant-select-arrow-icon svg {
    -webkit-transform: none;
            transform: none; }
  .ant-select .ant-select-selection__clear {
    opacity: 1; }
    .ant-select .ant-select-selection__clear, .ant-select .ant-select-selection__clear:hover, .ant-select .ant-select-selection__clear:active {
      display: none; }
  .ant-select .ant-select-open {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    border-radius: 4px;
    overflow: hidden;
    background-color: white !important;
    min-width: 164px; }
    .ant-select .ant-select-open .ant-select-arrow {
      background: #f2f6f8; }
    .ant-select .ant-select-open .ant-select-selection {
      background-color: white !important; }
    .ant-select .ant-select-open .ant-select-selection__clear {
      display: block; }
      .ant-select .ant-select-open .ant-select-selection__clear + .ant-select-arrow {
        display: none; }
  .ant-select .ant-select-selection__placeholder,
  .ant-select .ant-select-search__field__placeholder {
    color: #96a2aa; }

.mutation-select-wrapper .ant-select {
  width: 92px !important; }

.mutation-select-wrapper .ant-select-selection--single {
  height: 28px; }

.mutation-select-wrapper .ant-select-selection__rendered {
  line-height: 26px; }

.mutation-select-wrapper .ant-select-open .ant-select-arrow-icon svg {
  -webkit-transform: none;
          transform: none; }

.mutation-select-wrapper .ant-select-selection__placeholder,
.mutation-select-wrapper .ant-select-search__field__placeholder {
  color: #96a2aa; }

.ant-select-dropdown {
  font-size: 16px !important;
  line-height: 18px !important;
  color: #424d55 !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important; }
  .ant-select-dropdown .ant-select-dropdown-menu-item {
    display: flex;
    align-items: center; }
  .ant-select-dropdown .ant-select-dropdown-menu-item-active {
    background-color: #f2f6f8 !important; }
    .ant-select-dropdown .ant-select-dropdown-menu-item-active:hover {
      background-color: #e7eef2 !important; }
  .ant-select-dropdown .ant-select-dropdown-menu-item-selected {
    background-color: #f2f6f8 !important;
    font-weight: normal !important; }
    .ant-select-dropdown .ant-select-dropdown-menu-item-selected .reconfirm {
      display: block;
      width: 100%;
      height: 100%;
      font-size: 13px;
      margin-right: 20px; }

.table-select-wrapper {
  height: 100%;
  width: 100%; }
  .table-select-wrapper .ant-select {
    width: 100% !important;
    height: 100% !important;
    color: #96a2aa;
    background-color: inherit; }
  .table-select-wrapper .ant-select-selection,
  .table-select-wrapper .ant-select-selection__rendered {
    height: 100%;
    border: none;
    border-radius: 0px;
    background-color: inherit; }
    .table-select-wrapper .ant-select-selection .ant-select-selection-selected-value,
    .table-select-wrapper .ant-select-selection__rendered .ant-select-selection-selected-value {
      display: flex !important;
      align-items: center; }
  .table-select-wrapper .ant-select-selection__rendered {
    line-height: 39px;
    color: #424d55;
    padding-right: 25px; }
  .table-select-wrapper .ant-select-selection:focus,
  .table-select-wrapper .ant-select-selection:active {
    border: 0 !important;
    box-shadow: none; }
  .table-select-wrapper .ant-select-arrow {
    margin-top: 0px !important;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-top: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    .table-select-wrapper .ant-select-arrow svg {
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%) !important;
              transform: translate(-50%, -50%) !important; }
    .table-select-wrapper .ant-select-arrow:hover {
      background-color: #e7eef2; }
  .table-select-wrapper .anticon.anticon-down.ant-select-arrow-icon svg {
    position: absolute;
    right: 2px;
    border-radius: 0.4px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #96a2aa; }
  .table-select-wrapper .select-close {
    display: block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    opacity: 0.5; }
    .table-select-wrapper .select-close:hover {
      opacity: 1; }
  .table-select-wrapper .ant-select-open .ant-select-arrow-icon svg {
    -webkit-transform: none;
            transform: none; }
  .table-select-wrapper .ant-select-selection__clear {
    opacity: 1; }
    .table-select-wrapper .ant-select-selection__clear, .table-select-wrapper .ant-select-selection__clear:hover, .table-select-wrapper .ant-select-selection__clear:active {
      display: none; }
  .table-select-wrapper .ant-select-open {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    border-radius: 4px;
    overflow: hidden;
    background-color: white !important;
    min-width: 164px; }
    .table-select-wrapper .ant-select-open .ant-select-arrow {
      background: #f2f6f8; }
    .table-select-wrapper .ant-select-open .ant-select-selection {
      background-color: white !important; }
    .table-select-wrapper .ant-select-open .ant-select-selection__clear {
      display: block; }
      .table-select-wrapper .ant-select-open .ant-select-selection__clear + .ant-select-arrow {
        display: none; }
  .table-select-wrapper .ant-select-selection__placeholder,
  .table-select-wrapper .ant-select-search__field__placeholder {
    color: #96a2aa; }

.table-input-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px; }
  .table-input-wrapper .ant-input,
  .table-input-wrapper .ant-input .ant-input-number-input,
  .table-input-wrapper .ant-input-number,
  .table-input-wrapper .ant-input-number .ant-input-number-input {
    border: 1px solid rgba(0, 0, 0, 0);
    background-color: inherit;
    height: 28px;
    width: 100%;
    font-size: 16px;
    color: #424d55; }
  .table-input-wrapper .ant-input::-webkit-input-placeholder,
  .table-input-wrapper .ant-input input::-webkit-input-placeholder,
  .table-input-wrapper .ant-input-number::-webkit-input-placeholder,
  .table-input-wrapper .ant-input-number input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #96a2aa; }
  .table-input-wrapper .ant-input::-moz-placeholder,
  .table-input-wrapper .ant-input input::-moz-placeholder,
  .table-input-wrapper .ant-input-number::-moz-placeholder,
  .table-input-wrapper .ant-input-number input::-moz-placeholder {
    /* Firefox 19+ */
    color: #96a2aa; }
  .table-input-wrapper .ant-input:-ms-input-placeholder,
  .table-input-wrapper .ant-input input:-ms-input-placeholder,
  .table-input-wrapper .ant-input-number:-ms-input-placeholder,
  .table-input-wrapper .ant-input-number input:-ms-input-placeholder {
    /* IE 10+ */
    color: #96a2aa; }
  .table-input-wrapper .ant-input:-moz-placeholder,
  .table-input-wrapper .ant-input input:-moz-placeholder,
  .table-input-wrapper .ant-input-number:-moz-placeholder,
  .table-input-wrapper .ant-input-number input:-moz-placeholder {
    /* Firefox 18- */
    color: #96a2aa; }
  .table-input-wrapper .ant-input:hover, .table-input-wrapper .ant-input:active,
  .table-input-wrapper .ant-input input:hover,
  .table-input-wrapper .ant-input input:active,
  .table-input-wrapper .ant-input-number:hover,
  .table-input-wrapper .ant-input-number:active,
  .table-input-wrapper .ant-input-number input:hover,
  .table-input-wrapper .ant-input-number input:active {
    border-color: #0e8ce6 !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25); }

.ant-tooltip-arrow {
  display: none !important; }

.ant-popover-arrow {
  display: none !important; }

.ant-checkbox-wrapper {
  font-size: 16px !important;
  line-height: 21px;
  color: #424d55;
  display: flex !important;
  align-items: center; }
  .ant-checkbox-wrapper .ant-checkbox {
    padding-top: 0; }
    .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
      width: 16px;
      height: 16px;
      border: 2px solid #96a2aa;
      box-sizing: border-box;
      border-radius: 4px; }
    .ant-checkbox-wrapper .ant-checkbox + span {
      line-height: 0px;
      padding-left: 10px;
      padding-right: 0; }
  .ant-checkbox-wrapper .ant-checkbox-inner::after {
    left: 25%; }
  .ant-checkbox-wrapper .ant-checkbox-checked::after {
    display: none; }
  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    border: none; }

.validation-error-checkbox .ant-checkbox .ant-checkbox-inner {
  background: #fff2f3 !important;
  border: 2px solid #f73f3f !important; }

.anticon.anticon-down.ant-select-arrow-icon svg {
  border-top: 7px solid #96a2aa; }

.ethnic-religious-buttons .buttons-group .ant-radio-group {
  flex-wrap: wrap; }
  .ethnic-religious-buttons .buttons-group .ant-radio-group .ant-radio-button-wrapper {
    padding: 0 12px !important;
    text-align: center;
    margin-right: 8px;
    margin-bottom: 8px;
    border: 1px solid #dae1e6;
    color: #96a2aa;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px; }
    .ethnic-religious-buttons .buttons-group .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
      border: 1px solid #0e8ce6 !important;
      color: #0e8ce6 !important;
      box-shadow: none !important; }

@-webkit-keyframes progress {
  0% {
    background-position: -114px 0, 109px -47px, 114px 42px, 0 47px; }
  100% {
    background-position: 0 0, 109px -47px, 114px 42px, 0 47px; } }

@keyframes progress {
  0% {
    background-position: -114px 0, 109px -47px, 114px 42px, 0 47px; }
  100% {
    background-position: 0 0, 109px -47px, 114px 42px, 0 47px; } }

.swal2-popup .swal2-styled:focus {
  outline: none !important;
  box-shadow: none !important; }

.swal2-popup .swal2-styled.swal2-confirm.undo-button-class:hover {
  opacity: 0.9; }

.swal2-popup .swal2-styled.swal2-confirm.undo-button-class.progress {
  padding: 0;
  margin: 0;
  width: 114px;
  height: 47px;
  background-size: 100% 5px, 5px 100%, 100% 5px, 5px 100%;
  -webkit-animation: progress 15s linear forwards;
          animation: progress 15s linear forwards;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(99.99%, #b7ddf7), to(transparent)) !important;
  background-image: -webkit-linear-gradient(top, #b7ddf7 99.99%, transparent) !important;
  background-image: linear-gradient(to bottom, #b7ddf7 99.99%, transparent) !important;
  background-repeat: no-repeat;
  background-position: -114px 0, 109px -47px, 114px 42px, 0 47px; }

.anticon-check svg {
  color: #22b36e; }

.anticon-close svg {
  color: #f73f3f; }

.ant-popover {
  z-index: 99999 !important;
  min-width: 200px !important; }

.ant-popover-inner {
  display: flex !important;
  position: absolute !important;
  background-color: #fff !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px !important; }
  .ant-popover-inner.ant-popover-inner-content {
    padding: 0 !important; }

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  display: none !important; }

th {
  position: relative; }

.react-resizable {
  position: relative; }

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize; }

.ant-table {
  font-size: 16px !important; }
  .ant-table .ant-table-thead tr {
    background-color: #f2f6f8; }
  .ant-table .ant-table-thead .ant-table-selection-column {
    position: relative; }
    .ant-table .ant-table-thead .ant-table-selection-column .ant-table-header-column {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .ant-table .ant-table-tbody {
    background-color: #fff; }
    .ant-table .ant-table-tbody tr td {
      height: 40px;
      padding: 0; }
      .ant-table .ant-table-tbody tr td.ant-table-selection-column {
        position: relative; }
        .ant-table .ant-table-tbody tr td.ant-table-selection-column > span {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
      .ant-table .ant-table-tbody tr td.selection-cell {
        padding: 0;
        position: relative; }
        .ant-table .ant-table-tbody tr td.selection-cell .ant-checkbox-wrapper {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
    .ant-table .ant-table-tbody .ant-table-row-selected td {
      background-color: #f4fcff !important; }
    .ant-table .ant-table-tbody .ant-table-row td.select {
      padding: 0;
      position: relative; }
    .ant-table .ant-table-tbody .ant-table-row td.no-padding {
      padding: 0; }
    .ant-table .ant-table-tbody .ant-table-row .cell-padding {
      padding: 0 15px; }
    .ant-table .ant-table-tbody .ant-table-row .full-cell {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center; }
    .ant-table .ant-table-tbody .ant-table-row .bg-white {
      background-color: white; }
    .ant-table .ant-table-tbody .ant-table-row:hover td {
      background-color: #e7eef2; }
    .ant-table .ant-table-tbody .ant-table-row-hover td {
      background-color: #e7eef2 !important; }
  .ant-table .selection-cell {
    position: relative; }
    .ant-table .selection-cell .table-header-selection-chbx {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
      .ant-table .selection-cell .table-header-selection-chbx.partly::after {
        content: "";
        position: absolute;
        top: 3px;
        left: 4px;
        width: 8px;
        height: 8px;
        background-color: #0e8ce6;
        border-radius: 2px; }
  .ant-table .table-multiple-row {
    height: 37px; }
    .ant-table .table-multiple-row .notes-confirmation-wrapper {
      padding: 0 4px; }
      .ant-table .table-multiple-row .notes-confirmation-wrapper > div {
        padding: 0 11px !important;
        border: 1px solid rgba(0, 0, 0, 0);
        height: 28px;
        border-radius: 4px; }
        .ant-table .table-multiple-row .notes-confirmation-wrapper > div:hover, .ant-table .table-multiple-row .notes-confirmation-wrapper > div:active {
          border-color: #0e8ce6 !important;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25); }
    .ant-table .table-multiple-row.hovered {
      background-color: #e7eef2; }
      .ant-table .table-multiple-row.hovered .table-input-wrapper .ant-input,
      .ant-table .table-multiple-row.hovered .table-input-wrapper .ant-input-number {
        background-color: white;
        border: 1px solid #c8d1d8; }
      .ant-table .table-multiple-row.hovered .notes-confirmation-wrapper > div {
        background-color: white;
        border: 1px solid #c8d1d8; }
    .ant-table .table-multiple-row.toremove {
      background-color: #fff3f4; }
    .ant-table .table-multiple-row:not(:first-child) {
      border-top: 1px solid #e7eef2; }
  .ant-table .table-act-plus {
    width: 35px;
    height: 100%;
    background-image: url(/static/media/circle-plus-grey.e424fd5a.svg);
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .ant-table .table-act-plus:hover {
      background-color: #0e8ce6;
      background-image: url(/static/media/circle-plus-white.6798f851.svg); }
  .ant-table .table-act-remove {
    width: 35px;
    height: 100%;
    background-image: url(/static/media/circle-minus-red.794179e4.svg);
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .ant-table .table-act-remove:hover {
      background-color: #f11e2c;
      background-image: url(/static/media/circle-minus-white.751920ab.svg); }
  .ant-table .validation-error {
    background-color: #f2f6f8;
    outline: 1px solid #f11e2c; }

.ant-popover-inner {
  display: flex !important;
  position: absolute !important;
  background-color: #fff !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px !important; }
  .ant-popover-inner.ant-popover-inner-content {
    padding: 0 !important; }

.ant-table-pagination.ant-pagination {
  float: left !important;
  margin: 8px 16px !important; }

.ant-table-wrapper ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100% !important; }

.tumor-panel-opened .ant-table-content {
  max-height: calc(100vh - 198px);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease; }
  .tumor-panel-opened .ant-table-content .ant-table-scroll {
    height: calc(100vh - 198px);
    overflow-x: auto !important;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .tumor-panel-opened .ant-table-content .ant-table-scroll .ant-table-body {
      height: calc(100vh - 278px) !important;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease; }
    .tumor-panel-opened .ant-table-content .ant-table-scroll table {
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px);
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
  .tumor-panel-opened .ant-table-content .ant-table-fixed-left {
    height: calc(100vh - 219px);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; }
  .tumor-panel-opened .ant-table-content .ant-table-body-inner {
    height: calc(100vh - 278px) !important; }

.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  min-width: 90px; }
  .ant-table-bordered .ant-table-thead > tr > th.selection-cell,
  .ant-table-bordered .ant-table-tbody > tr > td.selection-cell {
    min-width: 50px; }
  .ant-table-bordered .ant-table-thead > tr > th.add-cell,
  .ant-table-bordered .ant-table-tbody > tr > td.add-cell {
    min-width: 35px; }

.sidebar-open .ant-table-wrapper,
.sidebar-open .ant-table-wrapper.classification-history-table-wrapper {
  width: calc(100vw - 197px) !important; }

.ant-table colgroup {
  min-width: 30px !important; }

.ant-slider {
  margin: 0 !important; }
  .ant-slider .ant-slider-rail {
    background-color: #dae1e6; }
  .ant-slider .ant-slide2-step {
    height: 3px; }
  .ant-slider .ant-slider-track {
    height: 3px; }
  .ant-slider .ant-slider-rail {
    height: 3px; }
  .ant-slider .ant-slider-handle {
    background-color: white;
    width: 9px;
    height: 9px;
    border: 1px solid #0e8ce6;
    margin-top: -3px;
    margin-left: -6px; }
    .ant-slider .ant-slider-handle::after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 18px;
      height: 18px;
      background-color: rgba(0, 0, 0, 0);
      border: 5px solid rgba(14, 140, 230, 0.3);
      border-radius: 50%;
      display: none; }
    .ant-slider .ant-slider-handle:hover {
      cursor: pointer; }
      .ant-slider .ant-slider-handle:hover::after {
        display: block; }
    .ant-slider .ant-slider-handle:focus {
      box-shadow: none; }
  .ant-slider .ant-slider-track {
    background-color: #42adfa; }
  .ant-slider:hover .ant-slider-track {
    background-color: #0e8ce6 !important; }
  .ant-slider:hover .ant-slider-handle {
    border: 1px solid #0e8ce6 !important; }
  .ant-slider:hover .ant-slider-rail {
    background-color: #c8d1d8 !important; }

.tumor-info-wrapper .ant-input-suffix svg path {
  fill: #96a2aa !important; }

.ant-modal-body .activity-items-table {
  max-height: 248px;
  overflow-y: scroll; }
  .ant-modal-body .activity-items-table .table-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0; }
    .ant-modal-body .activity-items-table .table-header .header-cell {
      display: inline-block; }
  .ant-modal-body .activity-items-table .cell {
    width: 33.3%;
    padding: 8px;
    text-align: center; }
  .ant-modal-body .activity-items-table .header-cell {
    background: #f2f6f8;
    font-weight: 600;
    font-size: 16px; }
  .ant-modal-body .activity-items-table .user-name {
    margin-left: 8px; }

.ant-modal-body .border {
  border: 1px solid #dae1e6; }

.ant-modal-body .action-item {
  width: 100px;
  overflow-x: hidden;
  margin: 0 auto; }
  .ant-modal-body .action-item .title-prev {
    opacity: 0.5; }

.ant-modal-body .prev-note {
  color: #96a2aa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left; }

.ant-modal-body .curr-note {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left; }

.zygosity-type-radio-group .ant-select.ant-select-enabled {
  width: 120px;
  height: 60px; }

.zygosity-type-radio-group .ant-select-selection {
  border: 1px solid rgba(0, 0, 0, 0.1); }

.zygosity-type-radio-group .ant-select-selection.ant-select-selection--single {
  height: 100% !important;
  border-radius: 0px !important; }
  .zygosity-type-radio-group .ant-select-selection.ant-select-selection--single svg path {
    fill: white; }
  .zygosity-type-radio-group .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered {
    width: 100%;
    display: flex;
    align-items: flex-end;
    left: -11px;
    height: 100%;
    padding-right: 0px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fff; }
    .zygosity-type-radio-group .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value {
      display: flex !important;
      justify-content: center;
      align-items: baseline;
      margin: 4px auto;
      margin-bottom: 10px;
      overflow: hidden;
      white-space: unset;
      text-overflow: unset;
      padding-right: 0; }
      .zygosity-type-radio-group .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value span {
        font-size: 20px;
        line-height: 100%;
        color: #fff !important; }
  .zygosity-type-radio-group .ant-select-selection.ant-select-selection--single .ant-select-arrow {
    top: 40px;
    right: -2px; }

.ant-table-wrapper.classification-history-table-wrapper {
  width: 100vw;
  height: 182px;
  overflow: hidden !important; }
  .ant-table-wrapper.classification-history-table-wrapper .ant-table-content .ant-table-fixed-left {
    height: 100%; }
  .ant-table-wrapper.classification-history-table-wrapper .ant-table-content .ant-table-body-inner {
    height: 100% !important; }
  .ant-table-wrapper.classification-history-table-wrapper .ant-table-content .ant-table-scroll {
    height: 100% !important;
    overflow-x: auto !important; }
    .ant-table-wrapper.classification-history-table-wrapper .ant-table-content .ant-table-scroll table {
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px);
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }

.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

.flex-column {
  flex-direction: column; }

.flex-row {
  flex-direction: row; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-auto {
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0; }

.flex-none {
  flex: none; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.justify-center {
  justify-content: center; }

.justify-between {
  justify-content: space-between; }

.justify-around {
  justify-content: space-around; }

.content-start {
  align-content: flex-start; }

.content-end {
  align-content: flex-end; }

.content-center {
  align-content: center; }

.content-between {
  align-content: space-between; }

.content-around {
  align-content: space-around; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-last {
  order: 99999; }

.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center; }

.items-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start; }

.items-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -ms-grid-row-align: baseline;
  align-items: baseline; }

.items-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -ms-grid-row-align: flex-end;
  align-items: flex-end; }

body {
  background-color: #e5e5e5 !important; }

.ant-btn {
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 1 !important;
  padding: 5px 34px !important;
  display: flex;
  align-items: center; }

.toolbar-btn-wrapper .ant-btn {
  color: #424d55;
  font-size: 14px !important;
  line-height: 18px !important;
  padding: 0 !important;
  width: 100%;
  height: 28px;
  border: none;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .toolbar-btn-wrapper .ant-btn:hover, .toolbar-btn-wrapper .ant-btn:active, .toolbar-btn-wrapper .ant-btn:focus {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    color: white;
    background-color: #0e8ce6; }
  .toolbar-btn-wrapper .ant-btn.ant-btn-primary, .toolbar-btn-wrapper .ant-btn.ant-btn-primary[disabled] {
    background-color: #0e8ce6 !important;
    color: white !important;
    border: none; }

.ant-btn-primary {
  background: #0e8ce6 !important; }
  .ant-btn-primary:hover {
    background: #1378c0 !important; }

.ant-btn-default {
  background: #b7ddf7 !important;
  border-color: #b7ddf7 !important;
  color: #fff !important; }

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
  color: #fff;
  background-color: #b7ddf7 !important; }

.ant-radio-inner:after {
  left: 4px;
  top: 4px; }

.ant-radio-wrapper {
  display: block;
  font-size: 16px !important; }

.ant-collapse {
  background: #fff;
  box-sizing: border-box;
  border: none !important;
  width: 168px !important; }
  .ant-collapse > .ant-collapse-item {
    border-top: none;
    border-radius: 4px !important;
    text-align: left;
    border-bottom: none !important; }
  .ant-collapse .ant-collapse-item-active {
    border-bottom: none !important; }
  .ant-collapse .ant-collapse-content-box {
    border: none; }
  .ant-collapse .ant-collapse-content {
    overflow: visible;
    overflow: initial;
    border-top: none;
    padding-bottom: 14px; }
  .ant-collapse .ant-collapse-header {
    color: #424d55 !important;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-bottom: none !important;
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    line-height: 14px !important;
    padding: 16px 0 16px 0 !important;
    border-top: 1px solid #dae1e6 !important; }
    .ant-collapse .ant-collapse-header svg {
      width: 12px;
      height: 10px;
      fill: #424d55;
      margin-right: 11px; }
      .ant-collapse .ant-collapse-header svg * {
        fill: #424d55; }
    .ant-collapse .ant-collapse-header label {
      margin: 0; }
  .ant-collapse .collapse-childrens {
    width: 153px !important; }
    .ant-collapse .collapse-childrens .ant-collapse-header {
      border-top: none !important; }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 15px;
    padding-right: 0; }
  .ant-collapse .panel-header {
    display: flex;
    justify-content: space-between !important;
    flex-direction: row;
    align-items: center;
    background-color: #fff; }
  .ant-collapse .ant-radio-button-wrapper {
    margin-right: 8px;
    border-radius: 4px;
    background: #fff;
    padding: 0 20px;
    border: 1px solid #dae1e6;
    color: #96a2aa; }
  .ant-collapse .ant-radio-button-wrapper-checked {
    border: 1px solid #0e8ce6;
    color: #0e8ce6;
    box-shadow: none; }

.ant-select {
  color: #96a2aa; }
  .ant-select .ant-select-selection,
  .ant-select .ant-select-selection__rendered {
    height: 100%;
    background-color: inherit; }
    .ant-select .ant-select-selection .ant-select-selection-selected-value,
    .ant-select .ant-select-selection__rendered .ant-select-selection-selected-value {
      display: flex !important;
      align-items: center; }
  .ant-select .ant-select-selection__rendered {
    line-height: 39px;
    color: #424d55;
    padding-right: 25px; }
  .ant-select .ant-select-arrow {
    margin-top: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    .ant-select .ant-select-arrow .anticon.anticon-down.ant-select-arrow-icon {
      width: 10px;
      height: 10px;
      background-image: url(/static/media/arrowDown.20a878b2.svg);
      background-repeat: no-repeat;
      background-position: center; }
    .ant-select .ant-select-arrow svg {
      display: none !important; }
  .ant-select .anticon.anticon-down.ant-select-arrow-icon svg {
    position: absolute;
    right: 2px;
    border-radius: 0.4px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #96a2aa; }
  .ant-select .select-close {
    display: block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    opacity: 0.5; }
    .ant-select .select-close:hover {
      opacity: 1; }
  .ant-select .ant-select-open .ant-select-arrow-icon svg {
    -webkit-transform: none;
            transform: none; }
  .ant-select .ant-select-selection__clear {
    opacity: 1; }
    .ant-select .ant-select-selection__clear, .ant-select .ant-select-selection__clear:hover, .ant-select .ant-select-selection__clear:active {
      display: none; }
  .ant-select .ant-select-open {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    border-radius: 4px;
    overflow: hidden;
    background-color: white !important;
    min-width: 164px; }
    .ant-select .ant-select-open .ant-select-arrow {
      background: #f2f6f8; }
    .ant-select .ant-select-open .ant-select-selection {
      background-color: white !important; }
    .ant-select .ant-select-open .ant-select-selection__clear {
      display: block; }
      .ant-select .ant-select-open .ant-select-selection__clear + .ant-select-arrow {
        display: none; }
  .ant-select .ant-select-selection__placeholder,
  .ant-select .ant-select-search__field__placeholder {
    color: #96a2aa; }

.mutation-select-wrapper .ant-select {
  width: 92px !important; }

.mutation-select-wrapper .ant-select-selection--single {
  height: 28px; }

.mutation-select-wrapper .ant-select-selection__rendered {
  line-height: 26px; }

.mutation-select-wrapper .ant-select-open .ant-select-arrow-icon svg {
  -webkit-transform: none;
          transform: none; }

.mutation-select-wrapper .ant-select-selection__placeholder,
.mutation-select-wrapper .ant-select-search__field__placeholder {
  color: #96a2aa; }

.ant-select-dropdown {
  font-size: 16px !important;
  line-height: 18px !important;
  color: #424d55 !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important; }
  .ant-select-dropdown .ant-select-dropdown-menu-item {
    display: flex;
    align-items: center; }
  .ant-select-dropdown .ant-select-dropdown-menu-item-active {
    background-color: #f2f6f8 !important; }
    .ant-select-dropdown .ant-select-dropdown-menu-item-active:hover {
      background-color: #e7eef2 !important; }
  .ant-select-dropdown .ant-select-dropdown-menu-item-selected {
    background-color: #f2f6f8 !important;
    font-weight: normal !important; }
    .ant-select-dropdown .ant-select-dropdown-menu-item-selected .reconfirm {
      display: block;
      width: 100%;
      height: 100%;
      font-size: 13px;
      margin-right: 20px; }

.table-select-wrapper {
  height: 100%;
  width: 100%; }
  .table-select-wrapper .ant-select {
    width: 100% !important;
    height: 100% !important;
    color: #96a2aa;
    background-color: inherit; }
  .table-select-wrapper .ant-select-selection,
  .table-select-wrapper .ant-select-selection__rendered {
    height: 100%;
    border: none;
    border-radius: 0px;
    background-color: inherit; }
    .table-select-wrapper .ant-select-selection .ant-select-selection-selected-value,
    .table-select-wrapper .ant-select-selection__rendered .ant-select-selection-selected-value {
      display: flex !important;
      align-items: center; }
  .table-select-wrapper .ant-select-selection__rendered {
    line-height: 39px;
    color: #424d55;
    padding-right: 25px; }
  .table-select-wrapper .ant-select-selection:focus,
  .table-select-wrapper .ant-select-selection:active {
    border: 0 !important;
    box-shadow: none; }
  .table-select-wrapper .ant-select-arrow {
    margin-top: 0px !important;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-top: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
    .table-select-wrapper .ant-select-arrow svg {
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%) !important;
              transform: translate(-50%, -50%) !important; }
    .table-select-wrapper .ant-select-arrow:hover {
      background-color: #e7eef2; }
  .table-select-wrapper .anticon.anticon-down.ant-select-arrow-icon svg {
    position: absolute;
    right: 2px;
    border-radius: 0.4px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #96a2aa; }
  .table-select-wrapper .select-close {
    display: block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    opacity: 0.5; }
    .table-select-wrapper .select-close:hover {
      opacity: 1; }
  .table-select-wrapper .ant-select-open .ant-select-arrow-icon svg {
    -webkit-transform: none;
            transform: none; }
  .table-select-wrapper .ant-select-selection__clear {
    opacity: 1; }
    .table-select-wrapper .ant-select-selection__clear, .table-select-wrapper .ant-select-selection__clear:hover, .table-select-wrapper .ant-select-selection__clear:active {
      display: none; }
  .table-select-wrapper .ant-select-open {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    border-radius: 4px;
    overflow: hidden;
    background-color: white !important;
    min-width: 164px; }
    .table-select-wrapper .ant-select-open .ant-select-arrow {
      background: #f2f6f8; }
    .table-select-wrapper .ant-select-open .ant-select-selection {
      background-color: white !important; }
    .table-select-wrapper .ant-select-open .ant-select-selection__clear {
      display: block; }
      .table-select-wrapper .ant-select-open .ant-select-selection__clear + .ant-select-arrow {
        display: none; }
  .table-select-wrapper .ant-select-selection__placeholder,
  .table-select-wrapper .ant-select-search__field__placeholder {
    color: #96a2aa; }

.table-input-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px; }
  .table-input-wrapper .ant-input,
  .table-input-wrapper .ant-input .ant-input-number-input,
  .table-input-wrapper .ant-input-number,
  .table-input-wrapper .ant-input-number .ant-input-number-input {
    border: 1px solid rgba(0, 0, 0, 0);
    background-color: inherit;
    height: 28px;
    width: 100%;
    font-size: 16px;
    color: #424d55; }
  .table-input-wrapper .ant-input::-webkit-input-placeholder,
  .table-input-wrapper .ant-input input::-webkit-input-placeholder,
  .table-input-wrapper .ant-input-number::-webkit-input-placeholder,
  .table-input-wrapper .ant-input-number input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #96a2aa; }
  .table-input-wrapper .ant-input::-moz-placeholder,
  .table-input-wrapper .ant-input input::-moz-placeholder,
  .table-input-wrapper .ant-input-number::-moz-placeholder,
  .table-input-wrapper .ant-input-number input::-moz-placeholder {
    /* Firefox 19+ */
    color: #96a2aa; }
  .table-input-wrapper .ant-input:-ms-input-placeholder,
  .table-input-wrapper .ant-input input:-ms-input-placeholder,
  .table-input-wrapper .ant-input-number:-ms-input-placeholder,
  .table-input-wrapper .ant-input-number input:-ms-input-placeholder {
    /* IE 10+ */
    color: #96a2aa; }
  .table-input-wrapper .ant-input:-moz-placeholder,
  .table-input-wrapper .ant-input input:-moz-placeholder,
  .table-input-wrapper .ant-input-number:-moz-placeholder,
  .table-input-wrapper .ant-input-number input:-moz-placeholder {
    /* Firefox 18- */
    color: #96a2aa; }
  .table-input-wrapper .ant-input:hover, .table-input-wrapper .ant-input:active,
  .table-input-wrapper .ant-input input:hover,
  .table-input-wrapper .ant-input input:active,
  .table-input-wrapper .ant-input-number:hover,
  .table-input-wrapper .ant-input-number:active,
  .table-input-wrapper .ant-input-number input:hover,
  .table-input-wrapper .ant-input-number input:active {
    border-color: #0e8ce6 !important;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25); }

.ant-tooltip-arrow {
  display: none !important; }

.ant-popover-arrow {
  display: none !important; }

.ant-checkbox-wrapper {
  font-size: 16px !important;
  line-height: 21px;
  color: #424d55;
  display: flex !important;
  align-items: center; }
  .ant-checkbox-wrapper .ant-checkbox {
    padding-top: 0; }
    .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
      width: 16px;
      height: 16px;
      border: 2px solid #96a2aa;
      box-sizing: border-box;
      border-radius: 4px; }
    .ant-checkbox-wrapper .ant-checkbox + span {
      line-height: 0px;
      padding-left: 10px;
      padding-right: 0; }
  .ant-checkbox-wrapper .ant-checkbox-inner::after {
    left: 25%; }
  .ant-checkbox-wrapper .ant-checkbox-checked::after {
    display: none; }
  .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    border: none; }

.validation-error-checkbox .ant-checkbox .ant-checkbox-inner {
  background: #fff2f3 !important;
  border: 2px solid #f73f3f !important; }

.anticon.anticon-down.ant-select-arrow-icon svg {
  border-top: 7px solid #96a2aa; }

.ethnic-religious-buttons .buttons-group .ant-radio-group {
  flex-wrap: wrap; }
  .ethnic-religious-buttons .buttons-group .ant-radio-group .ant-radio-button-wrapper {
    padding: 0 12px !important;
    text-align: center;
    margin-right: 8px;
    margin-bottom: 8px;
    border: 1px solid #dae1e6;
    color: #96a2aa;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px; }
    .ethnic-religious-buttons .buttons-group .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
      border: 1px solid #0e8ce6 !important;
      color: #0e8ce6 !important;
      box-shadow: none !important; }

@keyframes progress {
  0% {
    background-position: -114px 0, 109px -47px, 114px 42px, 0 47px; }
  100% {
    background-position: 0 0, 109px -47px, 114px 42px, 0 47px; } }

.swal2-popup .swal2-styled:focus {
  outline: none !important;
  box-shadow: none !important; }

.swal2-popup .swal2-styled.swal2-confirm.undo-button-class:hover {
  opacity: 0.9; }

.swal2-popup .swal2-styled.swal2-confirm.undo-button-class.progress {
  padding: 0;
  margin: 0;
  width: 114px;
  height: 47px;
  background-size: 100% 5px, 5px 100%, 100% 5px, 5px 100%;
  -webkit-animation: progress 15s linear forwards;
          animation: progress 15s linear forwards;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(99.99%, #b7ddf7), to(transparent)) !important;
  background-image: -webkit-linear-gradient(top, #b7ddf7 99.99%, transparent) !important;
  background-image: linear-gradient(to bottom, #b7ddf7 99.99%, transparent) !important;
  background-repeat: no-repeat;
  background-position: -114px 0, 109px -47px, 114px 42px, 0 47px; }

.anticon-check svg {
  color: #22b36e; }

.anticon-close svg {
  color: #f73f3f; }

.ant-popover {
  z-index: 99999 !important;
  min-width: 200px !important; }

.ant-popover-inner {
  display: flex !important;
  position: absolute !important;
  background-color: #fff !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px !important; }
  .ant-popover-inner.ant-popover-inner-content {
    padding: 0 !important; }

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  display: none !important; }

th {
  position: relative; }

.react-resizable {
  position: relative; }

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize; }

.ant-table {
  font-size: 16px !important; }
  .ant-table .ant-table-thead tr {
    background-color: #f2f6f8; }
  .ant-table .ant-table-thead .ant-table-selection-column {
    position: relative; }
    .ant-table .ant-table-thead .ant-table-selection-column .ant-table-header-column {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .ant-table .ant-table-tbody {
    background-color: #fff; }
    .ant-table .ant-table-tbody tr td {
      height: 40px;
      padding: 0; }
      .ant-table .ant-table-tbody tr td.ant-table-selection-column {
        position: relative; }
        .ant-table .ant-table-tbody tr td.ant-table-selection-column > span {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
      .ant-table .ant-table-tbody tr td.selection-cell {
        padding: 0;
        position: relative; }
        .ant-table .ant-table-tbody tr td.selection-cell .ant-checkbox-wrapper {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
    .ant-table .ant-table-tbody .ant-table-row-selected td {
      background-color: #f4fcff !important; }
    .ant-table .ant-table-tbody .ant-table-row td.select {
      padding: 0;
      position: relative; }
    .ant-table .ant-table-tbody .ant-table-row td.no-padding {
      padding: 0; }
    .ant-table .ant-table-tbody .ant-table-row .cell-padding {
      padding: 0 15px; }
    .ant-table .ant-table-tbody .ant-table-row .full-cell {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center; }
    .ant-table .ant-table-tbody .ant-table-row .bg-white {
      background-color: white; }
    .ant-table .ant-table-tbody .ant-table-row:hover td {
      background-color: #e7eef2; }
    .ant-table .ant-table-tbody .ant-table-row-hover td {
      background-color: #e7eef2 !important; }
  .ant-table .selection-cell {
    position: relative; }
    .ant-table .selection-cell .table-header-selection-chbx {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
      .ant-table .selection-cell .table-header-selection-chbx.partly::after {
        content: "";
        position: absolute;
        top: 3px;
        left: 4px;
        width: 8px;
        height: 8px;
        background-color: #0e8ce6;
        border-radius: 2px; }
  .ant-table .table-multiple-row {
    height: 37px; }
    .ant-table .table-multiple-row .notes-confirmation-wrapper {
      padding: 0 4px; }
      .ant-table .table-multiple-row .notes-confirmation-wrapper > div {
        padding: 0 11px !important;
        border: 1px solid rgba(0, 0, 0, 0);
        height: 28px;
        border-radius: 4px; }
        .ant-table .table-multiple-row .notes-confirmation-wrapper > div:hover, .ant-table .table-multiple-row .notes-confirmation-wrapper > div:active {
          border-color: #0e8ce6 !important;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25); }
    .ant-table .table-multiple-row.hovered {
      background-color: #e7eef2; }
      .ant-table .table-multiple-row.hovered .table-input-wrapper .ant-input,
      .ant-table .table-multiple-row.hovered .table-input-wrapper .ant-input-number {
        background-color: white;
        border: 1px solid #c8d1d8; }
      .ant-table .table-multiple-row.hovered .notes-confirmation-wrapper > div {
        background-color: white;
        border: 1px solid #c8d1d8; }
    .ant-table .table-multiple-row.toremove {
      background-color: #fff3f4; }
    .ant-table .table-multiple-row:not(:first-child) {
      border-top: 1px solid #e7eef2; }
  .ant-table .table-act-plus {
    width: 35px;
    height: 100%;
    background-image: url(/static/media/circle-plus-grey.e424fd5a.svg);
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .ant-table .table-act-plus:hover {
      background-color: #0e8ce6;
      background-image: url(/static/media/circle-plus-white.6798f851.svg); }
  .ant-table .table-act-remove {
    width: 35px;
    height: 100%;
    background-image: url(/static/media/circle-minus-red.794179e4.svg);
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .ant-table .table-act-remove:hover {
      background-color: #f11e2c;
      background-image: url(/static/media/circle-minus-white.751920ab.svg); }
  .ant-table .validation-error {
    background-color: #f2f6f8;
    outline: 1px solid #f11e2c; }

.ant-popover-inner {
  display: flex !important;
  position: absolute !important;
  background-color: #fff !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px !important; }
  .ant-popover-inner.ant-popover-inner-content {
    padding: 0 !important; }

.ant-table-pagination.ant-pagination {
  float: left !important;
  margin: 8px 16px !important; }

.ant-table-wrapper ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100% !important; }

.tumor-panel-opened .ant-table-content {
  max-height: calc(100vh - 198px);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease; }
  .tumor-panel-opened .ant-table-content .ant-table-scroll {
    height: calc(100vh - 198px);
    overflow-x: auto !important;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .tumor-panel-opened .ant-table-content .ant-table-scroll .ant-table-body {
      height: calc(100vh - 278px) !important;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease; }
    .tumor-panel-opened .ant-table-content .ant-table-scroll table {
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px);
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
  .tumor-panel-opened .ant-table-content .ant-table-fixed-left {
    height: calc(100vh - 219px);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; }
  .tumor-panel-opened .ant-table-content .ant-table-body-inner {
    height: calc(100vh - 278px) !important; }

.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  min-width: 90px; }
  .ant-table-bordered .ant-table-thead > tr > th.selection-cell,
  .ant-table-bordered .ant-table-tbody > tr > td.selection-cell {
    min-width: 50px; }
  .ant-table-bordered .ant-table-thead > tr > th.add-cell,
  .ant-table-bordered .ant-table-tbody > tr > td.add-cell {
    min-width: 35px; }

.sidebar-open .ant-table-wrapper,
.sidebar-open .ant-table-wrapper.classification-history-table-wrapper {
  width: calc(100vw - 197px) !important; }

.ant-table colgroup {
  min-width: 30px !important; }

.ant-slider {
  margin: 0 !important; }
  .ant-slider .ant-slider-rail {
    background-color: #dae1e6; }
  .ant-slider .ant-slide2-step {
    height: 3px; }
  .ant-slider .ant-slider-track {
    height: 3px; }
  .ant-slider .ant-slider-rail {
    height: 3px; }
  .ant-slider .ant-slider-handle {
    background-color: white;
    width: 9px;
    height: 9px;
    border: 1px solid #0e8ce6;
    margin-top: -3px;
    margin-left: -6px; }
    .ant-slider .ant-slider-handle::after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: 18px;
      height: 18px;
      background-color: rgba(0, 0, 0, 0);
      border: 5px solid rgba(14, 140, 230, 0.3);
      border-radius: 50%;
      display: none; }
    .ant-slider .ant-slider-handle:hover {
      cursor: pointer; }
      .ant-slider .ant-slider-handle:hover::after {
        display: block; }
    .ant-slider .ant-slider-handle:focus {
      box-shadow: none; }
  .ant-slider .ant-slider-track {
    background-color: #42adfa; }
  .ant-slider:hover .ant-slider-track {
    background-color: #0e8ce6 !important; }
  .ant-slider:hover .ant-slider-handle {
    border: 1px solid #0e8ce6 !important; }
  .ant-slider:hover .ant-slider-rail {
    background-color: #c8d1d8 !important; }

.tumor-info-wrapper .ant-input-suffix svg path {
  fill: #96a2aa !important; }

.ant-modal-body .activity-items-table {
  max-height: 248px;
  overflow-y: scroll; }
  .ant-modal-body .activity-items-table .table-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0; }
    .ant-modal-body .activity-items-table .table-header .header-cell {
      display: inline-block; }
  .ant-modal-body .activity-items-table .cell {
    width: 33.3%;
    padding: 8px;
    text-align: center; }
  .ant-modal-body .activity-items-table .header-cell {
    background: #f2f6f8;
    font-weight: 600;
    font-size: 16px; }
  .ant-modal-body .activity-items-table .user-name {
    margin-left: 8px; }

.ant-modal-body .border {
  border: 1px solid #dae1e6; }

.ant-modal-body .action-item {
  width: 100px;
  overflow-x: hidden;
  margin: 0 auto; }
  .ant-modal-body .action-item .title-prev {
    opacity: 0.5; }

.ant-modal-body .prev-note {
  color: #96a2aa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left; }

.ant-modal-body .curr-note {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left; }

.zygosity-type-radio-group .ant-select.ant-select-enabled {
  width: 120px;
  height: 60px; }

.zygosity-type-radio-group .ant-select-selection {
  border: 1px solid rgba(0, 0, 0, 0.1); }

.zygosity-type-radio-group .ant-select-selection.ant-select-selection--single {
  height: 100% !important;
  border-radius: 0px !important; }
  .zygosity-type-radio-group .ant-select-selection.ant-select-selection--single svg path {
    fill: white; }
  .zygosity-type-radio-group .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered {
    width: 100%;
    display: flex;
    align-items: flex-end;
    left: -11px;
    height: 100%;
    padding-right: 0px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fff; }
    .zygosity-type-radio-group .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value {
      display: flex !important;
      justify-content: center;
      align-items: baseline;
      margin: 4px auto;
      margin-bottom: 10px;
      overflow: hidden;
      white-space: unset;
      text-overflow: unset;
      padding-right: 0; }
      .zygosity-type-radio-group .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value span {
        font-size: 20px;
        line-height: 100%;
        color: #fff !important; }
  .zygosity-type-radio-group .ant-select-selection.ant-select-selection--single .ant-select-arrow {
    top: 40px;
    right: -2px; }

.ant-table-wrapper.classification-history-table-wrapper {
  width: 100vw;
  height: 182px;
  overflow: hidden !important; }
  .ant-table-wrapper.classification-history-table-wrapper .ant-table-content .ant-table-fixed-left {
    height: 100%; }
  .ant-table-wrapper.classification-history-table-wrapper .ant-table-content .ant-table-body-inner {
    height: 100% !important; }
  .ant-table-wrapper.classification-history-table-wrapper .ant-table-content .ant-table-scroll {
    height: 100% !important;
    overflow-x: auto !important; }
    .ant-table-wrapper.classification-history-table-wrapper .ant-table-content .ant-table-scroll table {
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px);
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }

.App {
  overflow: hidden;
  background-color: white; }

.App-header {
  position: relative;
  top: 0;
  z-index: 1;
  background: #fff;
  border-bottom: 1px solid #dae1e6;
  color: white; }
  .App-header .App-header-menu-button {
    cursor: pointer;
    text-align: right;
    margin-right: 24px; }
  .App-header .ant-select.ant-select-enabled {
    width: 95px !important; }

.App-link {
  color: #61dafb; }

.SingleTestPage_main-page__3zcwl {
  display: flex;
  justify-content: flex-start;
  z-index: 0;
  width: 100%; }
  .SingleTestPage_main-page__3zcwl .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    margin-right: 11px; }
  .SingleTestPage_main-page__3zcwl .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  .SingleTestPage_main-page__3zcwl .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }
  .SingleTestPage_main-page__3zcwl .main-content-wrapper {
    background-color: #f2f6f8;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    z-index: 0;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    -webkit-transform: translate(-155px, 0px);
            transform: translate(-155px, 0px); }
    .SingleTestPage_main-page__3zcwl .main-content-wrapper.sidebar-open {
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px); }
    .SingleTestPage_main-page__3zcwl .main-content-wrapper .tumor-toolbar-collapse {
      display: flex;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out; }
      .SingleTestPage_main-page__3zcwl .main-content-wrapper .tumor-toolbar-collapse.out {
        max-height: 1000px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        z-index: 5; }
    .SingleTestPage_main-page__3zcwl .main-content-wrapper .toolbar-collapse {
      display: flex;
      width: 100%;
      z-index: 2; }
      .SingleTestPage_main-page__3zcwl .main-content-wrapper .toolbar-collapse.shadow {
        border-bottom: none;
        box-shadow: 0 2px 3px 1px #dae1e680; }
  .SingleTestPage_main-page__3zcwl .sidebar-wrapper {
    display: flex;
    height: 100%;
    z-index: 2;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    -webkit-transform: translate(-155px, 0px);
            transform: translate(-155px, 0px);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .SingleTestPage_main-page__3zcwl .sidebar-wrapper.sidebar-open {
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px); }

.SideBarLayout_sidebar-layout-wrapper__SBIJQ .sidebar-layout {
  width: 100%;
  height: calc(100vh - 48px);
  border-right: #dae1e6 solid 1px; }
  .SideBarLayout_sidebar-layout-wrapper__SBIJQ .sidebar-layout.external-resources {
    width: 100%;
    height: 100%;
    background-color: #f4fcff; }
    .SideBarLayout_sidebar-layout-wrapper__SBIJQ .sidebar-layout.external-resources::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 15px 15px 15px 15px #f4fcff;
      border-right: none; }
  .SideBarLayout_sidebar-layout-wrapper__SBIJQ .sidebar-layout .content {
    width: 100%;
    opacity: 1;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease; }
    .SideBarLayout_sidebar-layout-wrapper__SBIJQ .sidebar-layout .content.sidebar-closed {
      opacity: 0; }
    .SideBarLayout_sidebar-layout-wrapper__SBIJQ .sidebar-layout .content .title {
      color: #424d55;
      font-weight: normal;
      font-size: 16px;
      text-align: left;
      padding: 12px;
      line-height: normal; }
    .SideBarLayout_sidebar-layout-wrapper__SBIJQ .sidebar-layout .content .subtitle {
      color: #96a2aa;
      line-height: 21px;
      font-size: 16px;
      text-align: left;
      margin-top: 12px; }
  .SideBarLayout_sidebar-layout-wrapper__SBIJQ .sidebar-layout .children {
    display: block;
    width: 100%;
    text-align: left;
    padding-left: 12px; }
  .SideBarLayout_sidebar-layout-wrapper__SBIJQ .sidebar-layout .icon-mode {
    display: block;
    position: absolute;
    width: 52px;
    height: 52px;
    top: -3px;
    left: 171px;
    cursor: pointer;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    z-index: 999; }
    .SideBarLayout_sidebar-layout-wrapper__SBIJQ .sidebar-layout .icon-mode.sidebar-closed {
      left: 155px;
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px); }
    .SideBarLayout_sidebar-layout-wrapper__SBIJQ .sidebar-layout .icon-mode:hover svg g {
      stroke: #1378c0; }

.Toolbar_toolbar-wrapper__wyW_z {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  background-color: #fff;
  font-style: normal;
  font-size: 14px;
  padding: 10px 25px;
  z-index: 2;
  border-bottom: #dae1e6 solid 1px; }
  .Toolbar_toolbar-wrapper__wyW_z .left-wrapper {
    display: flex;
    position: relative;
    justify-content: left;
    align-items: center; }
    .Toolbar_toolbar-wrapper__wyW_z .left-wrapper .mutation-select-wrapper {
      display: block;
      font-weight: 600;
      font-size: 14px;
      margin-right: 6px; }
    .Toolbar_toolbar-wrapper__wyW_z .left-wrapper .search-field-wrapper {
      width: 345px;
      min-width: 240px;
      max-width: 345px;
      display: flex;
      position: relative;
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease; }
      .Toolbar_toolbar-wrapper__wyW_z .left-wrapper .search-field-wrapper.sidebar-open {
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
        width: 240px; }
  .Toolbar_toolbar-wrapper__wyW_z .right-wrapper {
    display: flex;
    align-items: center;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease; }
  .Toolbar_toolbar-wrapper__wyW_z .search-field:focus {
    border: none; }
  .Toolbar_toolbar-wrapper__wyW_z input:focus {
    outline-offset: 0px !important;
    outline: none !important; }
  .Toolbar_toolbar-wrapper__wyW_z .search-icon {
    background: #e7eef2;
    border-radius: 50%;
    padding: 4px; }
  .Toolbar_toolbar-wrapper__wyW_z .anticon-close svg {
    color: #96a2aa; }
  .Toolbar_toolbar-wrapper__wyW_z .ant-input {
    height: 26px; }
  .Toolbar_toolbar-wrapper__wyW_z .confirmation-button {
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
    margin-left: auto;
    width: 195px;
    height: 28px;
    border-radius: 4px;
    background-image: url(/static/media/mail-grey.6ef25a86.svg);
    background-position: 12px center;
    background-repeat: no-repeat;
    padding-left: 35px;
    border: none;
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    color: #424d55; }
    .Toolbar_toolbar-wrapper__wyW_z .confirmation-button:enabled {
      cursor: pointer; }
    .Toolbar_toolbar-wrapper__wyW_z .confirmation-button:active, .Toolbar_toolbar-wrapper__wyW_z .confirmation-button:focus {
      outline: none; }
    .Toolbar_toolbar-wrapper__wyW_z .confirmation-button::-moz-focus-inner {
      border: 0; }
    .Toolbar_toolbar-wrapper__wyW_z .confirmation-button:hover:enabled, .Toolbar_toolbar-wrapper__wyW_z .confirmation-button:active:enabled {
      color: white;
      background-image: url(/static/media/mail-white.8be265b3.svg);
      background-color: #0e8ce6; }
  .Toolbar_toolbar-wrapper__wyW_z .toolbar-divider-line {
    height: 18px;
    width: 1px;
    background: #dae1e6;
    margin-left: 5px;
    margin-right: 5px; }
  .Toolbar_toolbar-wrapper__wyW_z .sort-wrapper {
    margin-right: 12px;
    border-radius: 4px; }
    .Toolbar_toolbar-wrapper__wyW_z .sort-wrapper .ant-select {
      min-width: 130px;
      max-height: 28px; }
      .Toolbar_toolbar-wrapper__wyW_z .sort-wrapper .ant-select .anticon.anticon-down.ant-select-arrow-icon {
        width: 20px;
        height: 20px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFNSURBVHgB5ZM7TsNAEIZnZ60gROMj5AqcAHwECkAUCLuMUnGCbE6Qykq5PApEKDiCcwSO4CO4oYmyu8z4EcwqRjaigins0T87nzw7vwH+XQhfWOin8QgDjQjryfX5HAaG8GEHUmaUjsuiEGooFLtgHM45tXx8mflNC61D0k87gW2Yc1DUtYLzfdCRPMpIz5bUtxc4QozLL3OQWzRnDRAsRDtoq5nuKYTvRkZr76hpLqyJgi3kTXGaXLyhNcc0ezxJrnLoEQE/6sOKc3+MupZDz0D45Qj6HkwfVvHGmPWtN3rlW4w3dG1c6wXka3AAmpxQpHoVNfqWFkmappShLKnukT/tU94jL43SUEggy1RbRilfoXYHL7bUfA43G2GiQ/setfXpzaXaQUUFLO1DMHZH4wIBAyO9f1b0S87qKb7AfgRsoPQ6oTGTvv78w/EBE7OhNIXKKRcAAAAASUVORK5CYII=); }
        .Toolbar_toolbar-wrapper__wyW_z .sort-wrapper .ant-select .anticon.anticon-down.ant-select-arrow-icon svg {
          display: none !important; }
      .Toolbar_toolbar-wrapper__wyW_z .sort-wrapper .ant-select .ant-select-selection {
        border: none;
        -webkit-transition: none;
        transition: none; }
      .Toolbar_toolbar-wrapper__wyW_z .sort-wrapper .ant-select .ant-select-selection--single {
        height: 28px; }
        .Toolbar_toolbar-wrapper__wyW_z .sort-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered {
          line-height: unset !important;
          padding-top: 2px; }
          .Toolbar_toolbar-wrapper__wyW_z .sort-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value {
            padding-right: 0 !important;
            color: #424d55; }
          .Toolbar_toolbar-wrapper__wyW_z .sort-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection__placeholder {
            top: 13px;
            right: 26px;
            left: unset;
            color: #424d55;
            font-weight: 600; }
        .Toolbar_toolbar-wrapper__wyW_z .sort-wrapper .ant-select .ant-select-selection--single .ant-select-arrow {
          top: 14px;
          right: 70px; }
      .Toolbar_toolbar-wrapper__wyW_z .sort-wrapper .ant-select .ant-select-selection-selected-value {
        float: right; }
    .Toolbar_toolbar-wrapper__wyW_z .sort-wrapper :hover {
      background: #e7eef2;
      border-radius: 4px; }
  .Toolbar_toolbar-wrapper__wyW_z .filter-wrapper {
    margin-right: 12px;
    border-radius: 4px; }
    .Toolbar_toolbar-wrapper__wyW_z .filter-wrapper .ant-select {
      min-width: 120px;
      max-height: 28px; }
      .Toolbar_toolbar-wrapper__wyW_z .filter-wrapper .ant-select .ant-select-arrow {
        width: 12px;
        height: 12px; }
      .Toolbar_toolbar-wrapper__wyW_z .filter-wrapper .ant-select .anticon.anticon-down.ant-select-arrow-icon {
        width: 10px;
        height: 10px;
        background-image: url(/static/media/arrowDown.20a878b2.svg);
        background-repeat: no-repeat; }
        .Toolbar_toolbar-wrapper__wyW_z .filter-wrapper .ant-select .anticon.anticon-down.ant-select-arrow-icon svg {
          display: none !important; }
      .Toolbar_toolbar-wrapper__wyW_z .filter-wrapper .ant-select .ant-select-selection {
        border: none;
        -webkit-transition: none;
        transition: none; }
      .Toolbar_toolbar-wrapper__wyW_z .filter-wrapper .ant-select .ant-select-selection--single {
        height: 28px; }
        .Toolbar_toolbar-wrapper__wyW_z .filter-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered {
          line-height: unset !important;
          padding-top: 2px; }
          .Toolbar_toolbar-wrapper__wyW_z .filter-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value {
            padding-right: 0 !important;
            color: #424d55; }
          .Toolbar_toolbar-wrapper__wyW_z .filter-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection__placeholder {
            top: 13px;
            right: 26px;
            left: unset;
            color: #424d55;
            font-weight: 600; }
        .Toolbar_toolbar-wrapper__wyW_z .filter-wrapper .ant-select .ant-select-selection--single .ant-select-arrow {
          top: 16px;
          right: 78px; }
      .Toolbar_toolbar-wrapper__wyW_z .filter-wrapper .ant-select .ant-select-selection-selected-value {
        float: right; }
    .Toolbar_toolbar-wrapper__wyW_z .filter-wrapper :hover {
      background: #e7eef2;
      border-radius: 4px; }

.LabeledTag_labeled-tag-wrapper__1Fo0o {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  align-items: center; }
  .LabeledTag_labeled-tag-wrapper__1Fo0o .label-text {
    font-size: medium;
    font-size: initial; }
    .LabeledTag_labeled-tag-wrapper__1Fo0o .label-text.unclassified {
      color: #96a2aa; }

.Tag_tag__3doR_ {
  width: 12px;
  height: 12px;
  min-width: 12px;
  max-height: 12px;
  border-radius: 50%;
  margin-right: 6px; }

.NumberVariants_number-variants-wrapper__BNJcb {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  color: #424d55;
  padding-right: 8px;
  width: 150px; }

.IgvLoadBAM_igv-btn__2rqW_ {
  width: 114px; }
  .IgvLoadBAM_igv-btn__2rqW_ .ant-btn.progress::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 4px;
    width: 100%;
    background-repeat: no-repeat; }
  .IgvLoadBAM_igv-btn__2rqW_ .ant-btn.progress--2::after {
    background-image: url(/static/media/btn-progress-part.49d37f90.svg);
    background-position: 0px calc(100% - 1px); }
  .IgvLoadBAM_igv-btn__2rqW_ .ant-btn.progress--3::after {
    background-image: url(/static/media/btn-progress-full.2f61e4f3.svg);
    background-position: 0px calc(100% - 1px); }
  .IgvLoadBAM_igv-btn__2rqW_ .ant-btn.progress > span + .anticon {
    margin-left: 5px;
    font-size: 11px; }
    .IgvLoadBAM_igv-btn__2rqW_ .ant-btn.progress > span + .anticon svg {
      fill: white; }

.AddResult_add-result-btn__2EATs {
  width: 114px; }
  .AddResult_add-result-btn__2EATs .ant-btn .btn-icon {
    width: 10px;
    height: 10px;
    background-image: url(/static/media/plus-grey.c7dc15b9.svg);
    background-position: center;
    background-size: contain;
    margin-right: 10px; }
  .AddResult_add-result-btn__2EATs .ant-btn:hover .btn-icon {
    background-image: url(/static/media/plus-white.10dcb1ae.svg); }

.EditResult_edit-result-btn__HaJsQ {
  width: 84px; }
  .EditResult_edit-result-btn__HaJsQ .ant-btn {
    background-color: #0e8ce6;
    color: white; }
    .EditResult_edit-result-btn__HaJsQ .ant-btn:hover {
      background-color: #1378c0; }

.Search_search-wrapper__2inBF {
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center;
  height: 28px; }
  .Search_search-wrapper__2inBF .ant-select {
    height: 28px; }
  .Search_search-wrapper__2inBF .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered {
    padding: 0;
    background: #e7eef2;
    border-radius: 32px;
    height: 28px; }
    .Search_search-wrapper__2inBF .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered .ant-input.ant-select-search__field {
      width: 100%;
      padding: 0 30px;
      border-radius: 32px;
      background: #e7eef2;
      outline-offset: 0 !important;
      outline: none !important;
      border: none; }
    .Search_search-wrapper__2inBF .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered .ant-input {
      height: 28px; }
  .Search_search-wrapper__2inBF .ant-select-selection__placeholder {
    width: 90%;
    height: 100%;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    background: none;
    z-index: 2;
    padding-left: 23px; }
  .Search_search-wrapper__2inBF .search-field:focus {
    border: none; }
  .Search_search-wrapper__2inBF input:focus {
    outline-offset: 0 !important;
    outline: none !important; }
  .Search_search-wrapper__2inBF .search-icon {
    background: #e7eef2;
    border-radius: 50%;
    padding: 4px; }
  .Search_search-wrapper__2inBF .anticon.anticon-search {
    position: absolute;
    left: 17px;
    z-index: 2; }
  .Search_search-wrapper__2inBF .clear-search-button {
    position: relative;
    right: 30px;
    z-index: 2;
    width: 16px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0);
    background-position: center;
    background-size: contain;
    cursor: pointer;
    border: 0;
    margin-left: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none; }
    .Search_search-wrapper__2inBF .clear-search-button:active, .Search_search-wrapper__2inBF .clear-search-button:focus {
      outline: none; }
    .Search_search-wrapper__2inBF .clear-search-button::-moz-focus-inner {
      border: 0; }
  .Search_search-wrapper__2inBF .anticon-close svg {
    color: #96a2aa; }
  .Search_search-wrapper__2inBF .ant-input {
    height: 26px; }

.portal-wrapper {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(43, 50, 56, 0.6);
  top: 0;
  left: 0;
  z-index: 10; }
  .portal-wrapper .portal {
    display: block;
    padding: 10px; }
    @media (max-width: 768px) {
      .portal-wrapper .portal {
        padding: 0; } }

.ExportConfirmPopup_export-confirm-popup__3bTsy {
  width: 414px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden; }

.ExportConfirmPopup_header__1ubG6 {
  background-color: #e7eef2;
  padding: 0 25px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.ExportConfirmPopup_header-title__1tqSU {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  color: #424d55;
  display: flex;
  align-items: center; }

.ExportConfirmPopup_header-close__2ZFcK {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  padding: 0;
  cursor: pointer; }

.ExportConfirmPopup_content__3s1_F {
  padding: 18px 47px 32px 36px; }

.ExportConfirmPopup_text__2znqA {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #778690;
  margin-bottom: 44px; }

.ExportConfirmPopup_content-footer__p3SUN {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.ExportConfirmPopup_btns__2u2sS {
  display: flex; }

.ExportConfirmPopup_btn__3b0LA {
  height: 35px !important;
  padding: 0 10px !important;
  border-radius: 4px !important;
  border: none !important;
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 18px !important;
  display: flex !important;
  justify-content: center;
  align-items: center; }

.ExportConfirmPopup_btn--submit__19VzK {
  margin-left: 5px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1) !important;
  color: white !important;
  background-color: #0e8ce6 !important; }

.FilterCheckboxItem_filter-item-wrapper__3lZIE .checkbox-inner-content {
  padding-top: 2px; }

.FilterCheckboxItem_filter-item-wrapper__3lZIE .ant-radio-wrapper-checked .item-label {
  font-weight: bold; }

.FilterCheckboxItem_filter-item-wrapper__3lZIE .ant-radio {
  display: none; }

.FilterCheckboxItem_filter-item-wrapper__3lZIE .anticon {
  margin-right: 11px; }

.RangeSlider_range-slider__2W2S8 {
  position: relative; }
  .RangeSlider_range-slider__2W2S8 .values {
    position: absolute;
    top: -10px;
    right: 0;
    color: #0e8ce6;
    font-size: 16px; }
  .RangeSlider_range-slider__2W2S8 .positions {
    display: flex;
    justify-content: space-between;
    font-size: 16px; }
  .RangeSlider_range-slider__2W2S8 .reset {
    position: absolute;
    top: -36px;
    right: 0;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    margin: 0;
    padding: 0;
    color: #c8d1d8;
    cursor: pointer; }
    .RangeSlider_range-slider__2W2S8 .reset:hover {
      color: #778690; }
  .RangeSlider_range-slider__2W2S8 .ant-slider {
    padding-top: 20px !important; }

.SelectionGroup_selection-group-wrapper__1EaHY {
  text-align: left; }
  .SelectionGroup_selection-group-wrapper__1EaHY .filter-item {
    margin-top: 11px; }
  .SelectionGroup_selection-group-wrapper__1EaHY .filter-item:first-child {
    margin-top: 0; }

.FilterChipIndicatorsItem_filter-chip-indicators-item__UAdVM {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 168px;
  max-width: 168px;
  background-color: #f2f6f8;
  margin-bottom: 7px;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  color: #424d55; }
  .FilterChipIndicatorsItem_filter-chip-indicators-item__UAdVM .value {
    margin-left: 2px;
    font-weight: 600; }
  .FilterChipIndicatorsItem_filter-chip-indicators-item__UAdVM .button {
    width: 16px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0);
    background-position: center;
    background-size: contain;
    cursor: pointer;
    border: 0px;
    margin-left: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none; }
    .FilterChipIndicatorsItem_filter-chip-indicators-item__UAdVM .button:active, .FilterChipIndicatorsItem_filter-chip-indicators-item__UAdVM .button:focus {
      outline: none; }
    .FilterChipIndicatorsItem_filter-chip-indicators-item__UAdVM .button::-moz-focus-inner {
      border: 0; }
  .FilterChipIndicatorsItem_filter-chip-indicators-item__UAdVM .icon {
    margin-right: 3px; }

.FilterChipIndicatorsItem_filter-chip-indicators-item__UAdVM:hover {
  background-color: #e7eef2; }

.FilterChipIndicators_filter-chip-indicators__2INdt .indicators-title {
  font-size: 14px;
  line-height: 18px;
  color: #424d55; }

.FilterChipIndicators_filter-chip-indicators__2INdt .indicators-items {
  margin-top: 5px;
  display: flex;
  flex-direction: column; }

.SidebarFilters_sidebar-filters__43heP .filters-range-slider {
  margin-left: -15px;
  width: 104%; }

.SidebarFilters_sidebar-filters__43heP .filters-title {
  color: #424d55;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  padding: 12px 0;
  line-height: normal; }

.SidebarFilters_sidebar-filters__43heP .clear-filters {
  padding: 12px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #96a2aa;
  cursor: pointer; }


.ConfirmationStatusPanel_confirmation-status-panel__1zX31 {
  background: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 8px 0; }
  .ConfirmationStatusPanel_confirmation-status-panel__1zX31 .status-item {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 8px; }
    .ConfirmationStatusPanel_confirmation-status-panel__1zX31 .status-item .confirmation-panel-icon {
      width: 17px;
      height: 17px;
      min-width: 17px;
      min-height: 17px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 8px; }
    .ConfirmationStatusPanel_confirmation-status-panel__1zX31 .status-item .confirmation-panel-label {
      font-size: 16px;
      line-height: 21px;
      color: #424d55;
      white-space: nowrap; }
    .ConfirmationStatusPanel_confirmation-status-panel__1zX31 .status-item:not(.selected) {
      cursor: pointer; }
      .ConfirmationStatusPanel_confirmation-status-panel__1zX31 .status-item:not(.selected):hover {
        background-color: #e7eef2; }
    .ConfirmationStatusPanel_confirmation-status-panel__1zX31 .status-item.selected {
      cursor: default;
      pointer-events: none; }
      .ConfirmationStatusPanel_confirmation-status-panel__1zX31 .status-item.selected .confirmation-panel-label {
        color: #96a2aa; }

.ConfirmationStatus_confirmation-status__1sG6d {
  position: relative;
  width: 100%;
  height: 100%; }
  .ConfirmationStatus_confirmation-status__1sG6d .panel-trigger {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .ConfirmationStatus_confirmation-status__1sG6d .confirmation-icon {
    width: 17px;
    height: 17px;
    min-width: 17px;
    min-height: 17px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
  .ConfirmationStatus_confirmation-status__1sG6d .confirmation-arrow-down {
    position: absolute;
    right: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 6px;
    height: 3px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
  .ConfirmationStatus_confirmation-status__1sG6d .confirmation-config-panel {
    position: fixed; }

.Notes_notes-wrapper___Qk19 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  min-width: 250px;
  padding: 9px;
  font-weight: normal;
  font-size: 16px;
  color: #96a2aa;
  cursor: pointer; }
  .Notes_notes-wrapper___Qk19 .notes-content-empty {
    width: 100%;
    height: 100%; }
  .Notes_notes-wrapper___Qk19 .notes-content {
    width: 80%;
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #424d55; }
  .Notes_notes-wrapper___Qk19 .notes-icon {
    line-height: 4px; }
  .Notes_notes-wrapper___Qk19 .edit-text-box {
    display: flex;
    position: absolute;
    top: 0;
    left: -16px;
    min-width: 250px;
    width: calc(100% + 32px);
    height: auto;
    background-color: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    z-index: 1; }
  .Notes_notes-wrapper___Qk19 .ant-form-explain {
    padding-left: 8px; }

.EditNotes_edit-notes-wrapper__1S3pF {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%; }
  .EditNotes_edit-notes-wrapper__1S3pF .divider {
    display: flex;
    margin: 0 auto;
    justify-content: flex-end;
    text-align: left;
    width: 95%;
    height: 2px;
    background: #0e8ce6; }
    .EditNotes_edit-notes-wrapper__1S3pF .divider .counter {
      display: block;
      position: absolute;
      top: -8px;
      font-weight: normal;
      font-size: 14px;
      color: #96a2aa; }
  .EditNotes_edit-notes-wrapper__1S3pF textarea {
    resize: none !important;
    border: none !important;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #424d55; }
    .EditNotes_edit-notes-wrapper__1S3pF textarea.ant-input {
      max-width: 86%;
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content; }
    .EditNotes_edit-notes-wrapper__1S3pF textarea.ant-input:focus {
      border: none !important;
      box-shadow: none !important; }

.ActionsFooter_actions-footer-wrapper__3e80o {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 8px 0 16px 0;
  padding-right: 9px; }
  .ActionsFooter_actions-footer-wrapper__3e80o .ant-btn {
    width: 64px;
    height: 28px;
    padding: 0 !important;
    border: none;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important; }
    .ActionsFooter_actions-footer-wrapper__3e80o .ant-btn span {
      margin: 0 auto; }
  .ActionsFooter_actions-footer-wrapper__3e80o .button-cancel {
    background: none;
    border: none;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    font-size: 14px;
    text-align: center;
    color: #96a2aa;
    padding-right: 16px;
    cursor: pointer; }
    .ActionsFooter_actions-footer-wrapper__3e80o .button-cancel:hover {
      color: #778690; }

.ExternalLink_external-link-wrapper__1s3Eb {
  display: flex;
  width: 100%;
  align-items: center; }
  .ExternalLink_external-link-wrapper__1s3Eb .external-link-set-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    cursor: pointer; }
    .ExternalLink_external-link-wrapper__1s3Eb .external-link-set-wrapper .external-link-text {
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #424d55;
      padding-left: 4px; }
    .ExternalLink_external-link-wrapper__1s3Eb .external-link-set-wrapper .icon {
      padding: 2px 5px 0; }
  .ExternalLink_external-link-wrapper__1s3Eb .external-link-set-wrapper:hover .external-link-text {
    color: #0e8ce6; }
  .ExternalLink_external-link-wrapper__1s3Eb .external-link-set-wrapper:hover svg path {
    stroke: #0e8ce6; }

.HighlightedCell_highlighted-cell__1TxfM {
  height: 100%;
  width: 100%; }
  .HighlightedCell_highlighted-cell__1TxfM .inner {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center; }
    .HighlightedCell_highlighted-cell__1TxfM .inner.highlighted {
      background-color: #e1f7ff; }
    .HighlightedCell_highlighted-cell__1TxfM .inner:not(.no-padding) {
      padding: 0 15px; }

.VariantTable_variant-table-wrapper__1M3YB .activity-icon-wrapper {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 29px;
  padding-top: 3px; }
  .VariantTable_variant-table-wrapper__1M3YB .activity-icon-wrapper:active, .VariantTable_variant-table-wrapper__1M3YB .activity-icon-wrapper:hover {
    background: #0e8ce6;
    border-radius: 4px; }
    .VariantTable_variant-table-wrapper__1M3YB .activity-icon-wrapper:active path, .VariantTable_variant-table-wrapper__1M3YB .activity-icon-wrapper:hover path {
      fill: #fff; }

.VariantTable_variant-table-wrapper__1M3YB .activity-icon-wrapper.disabled {
  cursor: not-allowed; }
  .VariantTable_variant-table-wrapper__1M3YB .activity-icon-wrapper.disabled svg {
    opacity: 0.5; }

.VariantTable_variant-table-wrapper__1M3YB .activity-log-details {
  position: absolute;
  z-index: 9;
  right: 24px;
  top: -8px;
  min-width: 320px;
  max-height: 121px;
  overflow-y: -webkit-paged-y;
  overflow-y: overlay;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background: #fff;
  padding: 0 20px 0 5px; }
  .VariantTable_variant-table-wrapper__1M3YB .activity-log-details .record {
    border-bottom: 1px solid #dae1e6;
    background: #fff; }
    .VariantTable_variant-table-wrapper__1M3YB .activity-log-details .record svg {
      height: 27px; }
    .VariantTable_variant-table-wrapper__1M3YB .activity-log-details .record .record__user {
      min-width: 84px;
      border-right: 1px solid #dae1e6; }
    .VariantTable_variant-table-wrapper__1M3YB .activity-log-details .record .record_changed-item {
      width: 100px;
      text-align: center;
      border-right: 1px solid #dae1e6; }
      .VariantTable_variant-table-wrapper__1M3YB .activity-log-details .record .record_changed-item .notes {
        display: block;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        padding: 0 6px; }
    .VariantTable_variant-table-wrapper__1M3YB .activity-log-details .record .record_time {
      min-width: 160px;
      text-align: center;
      font-size: 14px;
      color: #96a2aa; }
  .VariantTable_variant-table-wrapper__1M3YB .activity-log-details .record__item {
    margin: 10px 0;
    height: 18px; }
    .VariantTable_variant-table-wrapper__1M3YB .activity-log-details .record__item .user-name {
      margin-left: 11px;
      margin-right: 11px; }

.VariantTable_variant-table-wrapper__1M3YB .sorter {
  padding: 0; }
  .VariantTable_variant-table-wrapper__1M3YB .sorter .ant-table-header-column {
    width: 100%; }

.VariantTable_variant-table-wrapper__1M3YB .table-sorter-wrapper {
  cursor: pointer;
  min-height: 53px; }
  .VariantTable_variant-table-wrapper__1M3YB .table-sorter-wrapper:hover {
    background: #e7e7e7; }
  .VariantTable_variant-table-wrapper__1M3YB .table-sorter-wrapper svg {
    cursor: pointer;
    opacity: 0.5;
    width: 9px;
    height: 5px; }
    .VariantTable_variant-table-wrapper__1M3YB .table-sorter-wrapper svg:hover {
      opacity: 1; }
  .VariantTable_variant-table-wrapper__1M3YB .table-sorter-wrapper .arrow-active {
    opacity: 1; }
  .VariantTable_variant-table-wrapper__1M3YB .table-sorter-wrapper .top-arrow {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    margin-bottom: 2px; }
  .VariantTable_variant-table-wrapper__1M3YB .table-sorter-wrapper .table-sorter-title {
    margin-right: 6px; }

.VariantTable_variant-table-wrapper__1M3YB .table-select-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; }
  .VariantTable_variant-table-wrapper__1M3YB .table-select-wrapper .ant-select-selection__rendered {
    margin-left: 0 !important; }

.VariantTable_variant-table-wrapper__1M3YB .priority {
  padding-right: 7px; }

.VariantTable_variant-table-wrapper__1M3YB .ant-table-fixed {
  table-layout: fixed !important; }

.VariantTable_variant-table-wrapper__1M3YB .text {
  width: 70%;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #424d55;
  margin-left: 4px; }
  .VariantTable_variant-table-wrapper__1M3YB .text.cut {
    width: 65%;
    max-width: 110px; }

.VariantTable_variant-table-wrapper__1M3YB .numeric {
  text-align: right; }
  .VariantTable_variant-table-wrapper__1M3YB .numeric .inner {
    text-align: right;
    justify-content: flex-end; }

.VariantTable_variant-table-wrapper__1M3YB .hidden-classification {
  opacity: 0.3; }

.VariantTable_variant-table-wrapper__1M3YB .ant-table-content {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  max-height: calc(100vh - 150px); }
  .VariantTable_variant-table-wrapper__1M3YB .ant-table-content .ant-table-scroll {
    height: calc(100vh - 150px);
    overflow-x: auto !important; }
    .VariantTable_variant-table-wrapper__1M3YB .ant-table-content .ant-table-scroll .ant-table-body {
      height: calc(100vh - 230px) !important;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease; }
    .VariantTable_variant-table-wrapper__1M3YB .ant-table-content .ant-table-scroll table {
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px);
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
  .VariantTable_variant-table-wrapper__1M3YB .ant-table-content .ant-table-fixed-left {
    height: calc(100vh - 171px);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; }
  .VariantTable_variant-table-wrapper__1M3YB .ant-table-content .ant-table-body-inner {
    height: calc(100vh - 230px) !important; }

.EmptyState_empty-state__3QlD_ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box; }
  .EmptyState_empty-state__3QlD_ .image {
    width: 168px;
    height: 124px;
    margin-bottom: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }
  .EmptyState_empty-state__3QlD_ .title {
    font-size: 24px;
    line-height: 32px;
    color: #96a2aa;
    margin-bottom: 7px; }
  .EmptyState_empty-state__3QlD_ .description {
    font-size: 16px;
    line-height: 21px;
    color: #96a2aa; }

.IgvAlertPopup_igv-alert-popup__3nGXX {
  width: 414px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden; }
  .IgvAlertPopup_igv-alert-popup__3nGXX .header {
    background-color: #e7eef2;
    padding: 0 25px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .IgvAlertPopup_igv-alert-popup__3nGXX .header-close {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    background-position: center;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    padding: 0;
    cursor: pointer; }
  .IgvAlertPopup_igv-alert-popup__3nGXX .header-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #424d55;
    display: flex;
    align-items: center; }
  .IgvAlertPopup_igv-alert-popup__3nGXX .warning-sign {
    background-position: center;
    background-repeat: no-repeat;
    width: 22px;
    height: 19px;
    display: inline-block;
    margin-right: 13px; }
  .IgvAlertPopup_igv-alert-popup__3nGXX .content {
    padding: 18px 47px 32px 36px; }
  .IgvAlertPopup_igv-alert-popup__3nGXX .text {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #778690;
    margin-bottom: 44px; }
  .IgvAlertPopup_igv-alert-popup__3nGXX .content-footer {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .IgvAlertPopup_igv-alert-popup__3nGXX .btns {
    display: flex; }
  .IgvAlertPopup_igv-alert-popup__3nGXX .igv-alert-btn {
    width: 64px;
    height: 25px;
    padding: 0 !important;
    border-radius: 4px;
    border: none;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 18px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px; }
    .IgvAlertPopup_igv-alert-popup__3nGXX .igv-alert-btn:not(.igv-alert-btn--link) {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      color: white;
      background-color: #0e8ce6; }
  .IgvAlertPopup_igv-alert-popup__3nGXX .chbx .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 18px !important; }
    .IgvAlertPopup_igv-alert-popup__3nGXX .chbx .ant-checkbox-wrapper .ant-checkbox + span {
      color: #778690; }

.ConfirmationTable_confirmation-table__hJSAu .ant-table-empty .ant-table-body {
  overflow: hidden !important; }

.ConfirmationTable_confirmation-table__hJSAu .remover-cell {
  position: relative;
  padding-right: 27px !important; }
  .ConfirmationTable_confirmation-table__hJSAu .remover-cell span {
    white-space: nowrap; }
  .ConfirmationTable_confirmation-table__hJSAu .remover-cell button {
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    background-image: url(/static/media/bin.81da92f7.svg);
    background-size: 12px 15px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    display: none; }
    .ConfirmationTable_confirmation-table__hJSAu .remover-cell button:active, .ConfirmationTable_confirmation-table__hJSAu .remover-cell button:focus {
      outline: none; }
    .ConfirmationTable_confirmation-table__hJSAu .remover-cell button::-moz-focus-inner {
      border: 0; }
    .ConfirmationTable_confirmation-table__hJSAu .remover-cell button,
    .ConfirmationTable_confirmation-table__hJSAu .remover-cell button * {
      -webkit-transition: all 0.15s;
      transition: all 0.15s; }
    .ConfirmationTable_confirmation-table__hJSAu .remover-cell button:hover {
      background-image: url(/static/media/bin-red.a17c9140.svg); }

.ConfirmationTable_confirmation-table__hJSAu .ant-table-row:hover .remover-cell button {
  display: flex; }

.SendForConfirmationPopup_send-for-confirmation-popup__zJZG_ {
  width: 1336px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden; }
  .SendForConfirmationPopup_send-for-confirmation-popup__zJZG_ .confirmation-header {
    background-color: #e7eef2;
    padding: 0 25px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .SendForConfirmationPopup_send-for-confirmation-popup__zJZG_ .header-close {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    background-position: center;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    padding: 0;
    cursor: pointer; }
  .SendForConfirmationPopup_send-for-confirmation-popup__zJZG_ .header-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #424d55; }
  .SendForConfirmationPopup_send-for-confirmation-popup__zJZG_ .confirmation-content {
    height: 343px;
    overflow: auto; }
  .SendForConfirmationPopup_send-for-confirmation-popup__zJZG_ .confirmation-table {
    padding: 20px 60px; }
  .SendForConfirmationPopup_send-for-confirmation-popup__zJZG_ .confirmation-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    padding: 0 23px;
    border-top: 1px solid #dae1e6; }
  .SendForConfirmationPopup_send-for-confirmation-popup__zJZG_ .confirmation-btns {
    display: flex;
    margin-left: auto; }
  .SendForConfirmationPopup_send-for-confirmation-popup__zJZG_ .confirmation-btn {
    width: 120px;
    height: 44px;
    padding: 0 !important;
    border-radius: 4px;
    border: none;
    font-size: 16px !important;
    font-weight: normal !important;
    line-height: 21px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px; }
    .SendForConfirmationPopup_send-for-confirmation-popup__zJZG_ .confirmation-btn:not(.confirmation--link) {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      color: white;
      font-weight: 600 !important;
      background-color: #0e8ce6; }
    .SendForConfirmationPopup_send-for-confirmation-popup__zJZG_ .confirmation-btn[disabled] {
      background-color: #b7ddf7 !important;
      color: white !important; }

.UncheckConfirmationPopup_uncheck-confirmation-popup__2loaq {
  width: 400px;
  padding: 15px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden; }
  .UncheckConfirmationPopup_uncheck-confirmation-popup__2loaq .confirmation-header {
    height: 53px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dae1e6; }
  .UncheckConfirmationPopup_uncheck-confirmation-popup__2loaq .header-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #424d55;
    display: flex;
    align-items: center; }
  .UncheckConfirmationPopup_uncheck-confirmation-popup__2loaq .warning-sign {
    background-position: center;
    background-repeat: no-repeat;
    width: 22px;
    height: 19px;
    display: inline-block;
    margin-right: 13px; }
  .UncheckConfirmationPopup_uncheck-confirmation-popup__2loaq .confirmation-content {
    font-size: 14px;
    line-height: 18px;
    color: #424d55;
    padding: 17px 0; }
  .UncheckConfirmationPopup_uncheck-confirmation-popup__2loaq .confirmation-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px; }
  .UncheckConfirmationPopup_uncheck-confirmation-popup__2loaq .confirmation-btns {
    display: flex;
    margin-left: auto; }
  .UncheckConfirmationPopup_uncheck-confirmation-popup__2loaq .confirmation-btn {
    width: 64px;
    height: 25px;
    padding: 0 !important;
    border-radius: 4px;
    border: none;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 18px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px; }
    .UncheckConfirmationPopup_uncheck-confirmation-popup__2loaq .confirmation-btn:not(.confirmation--link) {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      color: white;
      background-color: #0e8ce6; }

.SetTestPhenotype_set-test-phenotype__2VrWh {
  background-color: white;
  padding: 48px;
  width: 422px;
  color: black; }
  .SetTestPhenotype_set-test-phenotype__2VrWh .set-test-phenotype-title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 21px; }
  .SetTestPhenotype_set-test-phenotype__2VrWh .set-test-phenotype-text {
    font-size: 12px;
    line-height: 16px;
    opacity: 0.6;
    margin-bottom: 20px; }
  .SetTestPhenotype_set-test-phenotype__2VrWh .set-test-phenotype-input-row {
    margin-bottom: 20px; }
  .SetTestPhenotype_set-test-phenotype__2VrWh .set-test-phenotype-btns {
    display: flex;
    align-items: center;
    justify-content: center; }
  .SetTestPhenotype_set-test-phenotype__2VrWh .set-test-phenotype-btn {
    text-transform: uppercase;
    font-size: 12px !important;
    font-weight: 600;
    width: 96px !important;
    display: flex;
    justify-content: center;
    align-items: center; }
    .SetTestPhenotype_set-test-phenotype__2VrWh .set-test-phenotype-btn[disabled] {
      color: white;
      border: none; }
      .SetTestPhenotype_set-test-phenotype__2VrWh .set-test-phenotype-btn[disabled]:hover {
        color: white; }
    .SetTestPhenotype_set-test-phenotype__2VrWh .set-test-phenotype-btn--link {
      color: #0e8ce6;
      border: none; }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 72px;
  height: 72px; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 5px solid #c8d1d8;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #c8d1d8 transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s; }

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.Header_header-wrapper__3cWXx .header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 48px;
  background-color: #424d55;
  font-style: normal;
  font-size: 16px;
  text-align: left; }

.Header_header-wrapper__3cWXx .left-wrapper {
  margin-left: 2em; }
  .Header_header-wrapper__3cWXx .left-wrapper .go-back-button {
    display: flex;
    width: 110px;
    height: 33px;
    justify-content: center;
    align-items: center;
    background-color: #0e8ce6;
    border-radius: 5px;
    cursor: pointer; }
    .Header_header-wrapper__3cWXx .left-wrapper .go-back-button .go-back-button-text {
      font-weight: 600;
      font-size: 18px;
      text-align: center;
      color: #fff; }
    .Header_header-wrapper__3cWXx .left-wrapper .go-back-button:hover {
      background-color: #1378c0; }
  .Header_header-wrapper__3cWXx .left-wrapper .lock-img {
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }

.Header_header-wrapper__3cWXx .right-side-item {
  width: auto;
  margin-right: 20px; }

.Header_header-wrapper__3cWXx .header-info-panel {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }
  .Header_header-wrapper__3cWXx .header-info-panel.out {
    max-height: 1000px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    z-index: 5; }

.HeaderIcon_header-icon-wrapper__RqXC6 {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px; }
  .HeaderIcon_header-icon-wrapper__RqXC6 .icon, .HeaderIcon_header-icon-wrapper__RqXC6 .icon-active {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .HeaderIcon_header-icon-wrapper__RqXC6 .icon.info:hover svg path, .HeaderIcon_header-icon-wrapper__RqXC6 .info.icon-active:hover svg path {
      fill: #22b36e; }
    .HeaderIcon_header-icon-wrapper__RqXC6 .icon.notification:hover svg path, .HeaderIcon_header-icon-wrapper__RqXC6 .notification.icon-active:hover svg path {
      fill: #ff835c; }
  .HeaderIcon_header-icon-wrapper__RqXC6 .icon-active.info svg path {
    fill: #22b36e; }
  .HeaderIcon_header-icon-wrapper__RqXC6 .icon-active.notification svg path {
    fill: #ff835c; }

.User_user-wrapper__24zUu {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  width: 32px;
  height: 32px; }
  .User_user-wrapper__24zUu .avatar-fallback {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    background-color: #0e8ce6;
    border-radius: 50%; }
  .User_user-wrapper__24zUu .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden; }
    .User_user-wrapper__24zUu .avatar img {
      object-fit: contain;
      width: 100%;
      height: 100%; }

.InfoPanel_info-panel-wrapper__1ZM9Z {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: white;
  font-style: normal;
  font-size: 14px;
  padding: 0px 55px 16px; }
  .InfoPanel_info-panel-wrapper__1ZM9Z .panel-type-wrapper {
    display: flex;
    align-items: center;
    margin-right: 80px;
    padding-top: 16px; }
  .InfoPanel_info-panel-wrapper__1ZM9Z .sample-type-wrapper {
    display: flex;
    align-items: center;
    margin-right: 80px;
    padding-top: 16px; }
  .InfoPanel_info-panel-wrapper__1ZM9Z .tumor-info-wrapper {
    display: flex;
    align-items: center;
    margin-right: 80px;
    padding-top: 16px; }
  .InfoPanel_info-panel-wrapper__1ZM9Z .patient-wrapper {
    display: flex;
    align-items: center;
    padding-top: 16px; }
  .InfoPanel_info-panel-wrapper__1ZM9Z .info-panel-label {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);
    margin-right: 12px;
    white-space: nowrap; }
  .InfoPanel_info-panel-wrapper__1ZM9Z .info-panel-value {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #222222; }
  .InfoPanel_info-panel-wrapper__1ZM9Z .info-panel-select {
    height: 32px; }
    .InfoPanel_info-panel-wrapper__1ZM9Z .info-panel-select .ant-select {
      height: 32px; }
      .InfoPanel_info-panel-wrapper__1ZM9Z .info-panel-select .ant-select .ant-select-selection-selected-value {
        line-height: 31px; }
        .InfoPanel_info-panel-wrapper__1ZM9Z .info-panel-select .ant-select .ant-select-selection-selected-value .label-text {
          font-size: 14px;
          color: #222222; }
  .InfoPanel_info-panel-wrapper__1ZM9Z .tumor-info-location {
    margin-left: 16px; }
  .InfoPanel_info-panel-wrapper__1ZM9Z .patient-age {
    margin-left: 16px; }

.TumorInfoPercent_tumor-info-percent-wrapper__1iNQ8 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 25px;
  position: relative; }

.TumorInfoPercent_slider__AYgoI {
  width: 146px; }
  .TumorInfoPercent_slider__AYgoI .ant-slider .ant-slider-handle {
    display: none; }
  .TumorInfoPercent_slider__AYgoI .ant-slider:hover .ant-slider-handle {
    display: block; }
  .TumorInfoPercent_slider__AYgoI .ant-slider .ant-slider-rail {
    background-color: rgba(0, 0, 0, 0.1);
    height: 4px;
    border-radius: 2px; }
  .TumorInfoPercent_slider__AYgoI .ant-slider .ant-slider-track {
    background-color: #2196f3;
    height: 4px;
    border-radius: 2px; }

.TumorInfoPercent_value__3q7mK {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #222222;
  margin-right: 8px; }

.TumorInfoPercent_title__NBKk3 {
  position: absolute;
  left: 0;
  top: -20px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.3); }

.SlideBar_slidebar__1MruM .slidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99; }
  .SlideBar_slidebar__1MruM .slidebar-wrapper.hidden {
    pointer-events: none; }
    .SlideBar_slidebar__1MruM .slidebar-wrapper.hidden .slidebar-background {
      opacity: 0; }
    .SlideBar_slidebar__1MruM .slidebar-wrapper.hidden .slidebar-content {
      right: -491px; }

.SlideBar_slidebar__1MruM .slidebar-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.SlideBar_slidebar__1MruM .slidebar-content {
  background-color: #fff;
  z-index: 1000;
  position: relative;
  width: 491px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s; }

.SlideBar_slidebar__1MruM .slidebar-close {
  position: absolute;
  top: 27px;
  right: 27px;
  width: 22px;
  height: 22px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/static/media/close-big.e0f0c2bf.svg);
  cursor: pointer; }

.SlideBar_slidebar__1MruM .slidebar-header {
  display: flex;
  align-items: center;
  padding: 20px 47px;
  border-bottom: 1px solid #dae1e6;
  height: 63px; }

.SlideBar_slidebar__1MruM .slidebar-title {
  font-weight: 600;
  font-size: 18px;
  color: #424d55; }

.SlideBar_slidebar__1MruM .slidebar-inner {
  height: calc(100% - 63px);
  overflow: auto; }

.ToggledInput_toggled-input__1X6XV .ti-wrapper {
  display: flex;
  align-items: center;
  width: 100%; }
  .ToggledInput_toggled-input__1X6XV .ti-wrapper .ti-label {
    width: 80px;
    margin-right: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #424d55; }
  .ToggledInput_toggled-input__1X6XV .ti-wrapper .ti-value {
    flex: 1 1; }
    .ToggledInput_toggled-input__1X6XV .ti-wrapper .ti-value .ti-text-value {
      font-size: 16px;
      line-height: 21px;
      color: #424d55; }
    .ToggledInput_toggled-input__1X6XV .ti-wrapper .ti-value .ti-input-value .ant-input {
      border: none;
      border-bottom: 1px solid #dae1e6;
      border-radius: 0px;
      height: 24px;
      padding: 0; }
      .ToggledInput_toggled-input__1X6XV .ti-wrapper .ti-value .ti-input-value .ant-input:active, .ToggledInput_toggled-input__1X6XV .ti-wrapper .ti-value .ti-input-value .ant-input:hover, .ToggledInput_toggled-input__1X6XV .ti-wrapper .ti-value .ti-input-value .ant-input:focus {
        border: none;
        outline: none;
        box-shadow: none;
        border-bottom: 1px solid #0e8ce6; }
    .ToggledInput_toggled-input__1X6XV .ti-wrapper .ti-value .ti-input-value .ant-input-suffix {
      right: 0; }
      .ToggledInput_toggled-input__1X6XV .ti-wrapper .ti-value .ti-input-value .ant-input-suffix i svg {
        display: none; }
      .ToggledInput_toggled-input__1X6XV .ti-wrapper .ti-value .ti-input-value .ant-input-suffix i:after {
        content: "clear";
        font-size: 13px; }
  .ToggledInput_toggled-input__1X6XV .ti-wrapper .ti-handler {
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0);
    background-image: url(/static/media/edit-grey.c56eb92b.svg);
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 28px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .ToggledInput_toggled-input__1X6XV .ti-wrapper .ti-handler.active {
      background-color: #0e8ce6;
      background-image: url(/static/media/edit-white.541408b7.svg); }
    .ToggledInput_toggled-input__1X6XV .ti-wrapper .ti-handler:hover {
      background-color: #1378c0;
      background-image: url(/static/media/edit-white.541408b7.svg); }

.GeneSelect_gene-select-wrapper__2rXPu {
  width: 100%; }
  .GeneSelect_gene-select-wrapper__2rXPu .ant-select {
    width: 100%; }
    .GeneSelect_gene-select-wrapper__2rXPu .ant-select .ant-select-selection {
      height: 44px !important;
      width: 100%; }
      .GeneSelect_gene-select-wrapper__2rXPu .ant-select .ant-select-selection .ant-select-selection__rendered {
        border: 1px solid #424d55;
        border-radius: 4px;
        padding: 0; }
      .GeneSelect_gene-select-wrapper__2rXPu .ant-select .ant-select-selection .ant-input {
        border: 0;
        height: 43px !important; }

.ValidationWrapper_validation-wrapper__oUkuI {
  width: 100%; }
  .ValidationWrapper_validation-wrapper__oUkuI .validation-wrapper-content {
    position: relative;
    width: 100%; }
    .ValidationWrapper_validation-wrapper__oUkuI .validation-wrapper-content.error .ant-input-number,
    .ValidationWrapper_validation-wrapper__oUkuI .validation-wrapper-content.error .ant-select-auto-complete .ant-select-selection__rendered,
    .ValidationWrapper_validation-wrapper__oUkuI .validation-wrapper-content.error .chromosome-select-wrapper .ant-select-selection,
    .ValidationWrapper_validation-wrapper__oUkuI .validation-wrapper-content.error .position-input-wrapper .ant-input-number,
    .ValidationWrapper_validation-wrapper__oUkuI .validation-wrapper-content.error .rcinput {
      border-color: #f11e2c;
      box-shadow: 0px 0px 5px #f11e2c; }
  .ValidationWrapper_validation-wrapper__oUkuI .error-message {
    position: absolute;
    bottom: -17px;
    right: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 131.99%;
    color: #f11e2c; }

.ResultConfig_result-config__1yDKl .content {
  padding: 39px 47px;
  text-align: left; }

.ResultConfig_result-config__1yDKl .rcinput {
  width: 100%;
  height: 44px;
  border: 1px solid #424d55;
  border-radius: 4px;
  padding: 16px;
  font-size: 16px;
  line-height: 21px;
  color: #424d55; }
  .ResultConfig_result-config__1yDKl .rcinput.disabled {
    pointer-events: none;
    opacity: 0.5; }

.ResultConfig_result-config__1yDKl .label {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #424d55; }

.ResultConfig_result-config__1yDKl .gene-row {
  display: flex;
  align-items: center;
  margin-bottom: 25px; }
  .ResultConfig_result-config__1yDKl .gene-row .label {
    margin-right: 8px; }

.ResultConfig_result-config__1yDKl .chr-pos-row {
  display: flex;
  align-items: flex-end;
  margin-bottom: 29px; }
  .ResultConfig_result-config__1yDKl .chr-pos-row .label {
    margin-bottom: 5px; }

.ResultConfig_result-config__1yDKl .chr-block {
  width: 130px; }

.ResultConfig_result-config__1yDKl .pos-block {
  flex: 1 1; }

.ResultConfig_result-config__1yDKl .chr-pos-div {
  margin: 0 12px;
  height: 44px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 21px;
  color: #424d55; }

.ResultConfig_result-config__1yDKl .allele-block {
  margin-bottom: 30px; }
  .ResultConfig_result-config__1yDKl .allele-block .label {
    margin-bottom: 7px; }

.ResultConfig_result-config__1yDKl .allele-radios {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px; }

.ResultConfig_result-config__1yDKl .allele-radio {
  width: 126px;
  height: 44px;
  border-radius: 4px;
  background-color: white;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 131.99%;
  color: #424d55;
  -webkit-transition: 0.25s;
  transition: 0.25s; }
  .ResultConfig_result-config__1yDKl .allele-radio.active, .ResultConfig_result-config__1yDKl .allele-radio:hover {
    background-color: #0e8ce6;
    color: white; }

.ResultConfig_result-config__1yDKl .allele-from-to-div {
  font-size: 16px;
  line-height: 21px;
  color: #424d55;
  margin: 5px; }

.ResultConfig_result-config__1yDKl .vaf-covarage-row {
  display: flex;
  margin-bottom: 22px; }

.ResultConfig_result-config__1yDKl .vaf,
.ResultConfig_result-config__1yDKl .covarage {
  display: flex; }
  .ResultConfig_result-config__1yDKl .vaf .label,
  .ResultConfig_result-config__1yDKl .covarage .label {
    margin-right: 25px; }

.ResultConfig_result-config__1yDKl .vaf {
  margin-right: 65px; }

.ResultConfig_result-config__1yDKl .allele-divider {
  height: 0px;
  width: 100%;
  border-top: 1px solid #dae1e6; }

.ResultConfig_result-config__1yDKl .allele-btn-hgvs-wrapper {
  margin: 41px 0 46px; }

.ResultConfig_result-config__1yDKl .allele-btn {
  width: 100%;
  height: 44px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #0e8ce6;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  -webkit-transition: 0.25s;
  transition: 0.25s; }
  .ResultConfig_result-config__1yDKl .allele-btn--hgvs {
    background-color: white;
    color: #0e8ce6; }
    .ResultConfig_result-config__1yDKl .allele-btn--hgvs:hover, .ResultConfig_result-config__1yDKl .allele-btn--hgvs.loaded {
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(14, 140, 230, 0)), color-stop(0.01%, #31a7fc), to(#0e8ce6));
      background: -webkit-linear-gradient(top, rgba(14, 140, 230, 0) 0%, #31a7fc 0.01%, #0e8ce6 100%);
      background: linear-gradient(180deg, rgba(14, 140, 230, 0) 0%, #31a7fc 0.01%, #0e8ce6 100%);
      color: white; }
    .ResultConfig_result-config__1yDKl .allele-btn--hgvs.loaded {
      position: relative; }
      .ResultConfig_result-config__1yDKl .allele-btn--hgvs.loaded::after {
        content: "";
        position: absolute;
        width: 17px;
        height: 17px;
        left: calc(50% + 50px);
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        background-image: url(/static/media/ok.8938e0e2.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; }
  .ResultConfig_result-config__1yDKl .allele-btn--add {
    background-color: #0e8ce6;
    color: white;
    margin: 43px 0 0; }
    .ResultConfig_result-config__1yDKl .allele-btn--add:hover {
      background: #1378c0; }

.ResultConfig_result-config__1yDKl .cp-row {
  display: flex;
  margin-bottom: 27px; }
  .ResultConfig_result-config__1yDKl .cp-row .label {
    margin-right: 14px; }

.ResultConfig_result-config__1yDKl .cp-result {
  font-size: 16px;
  line-height: 21px;
  color: #424d55; }

.ResultConfig_result-config__1yDKl .chromosome-select-wrapper .ant-select {
  width: 100%;
  height: 44px; }
  .ResultConfig_result-config__1yDKl .chromosome-select-wrapper .ant-select .ant-select-selection {
    border: 1px solid #424d55;
    border-radius: 4px; }

.ResultConfig_result-config__1yDKl .position-input-wrapper {
  height: 44px; }
  .ResultConfig_result-config__1yDKl .position-input-wrapper .ant-input-number {
    height: 44px;
    width: 100%;
    border: 1px solid #424d55;
    border-radius: 4px; }
  .ResultConfig_result-config__1yDKl .position-input-wrapper input {
    width: 100%;
    height: 44px;
    padding: 16px;
    font-size: 16px;
    line-height: 21px;
    color: #424d55; }

.ActionAddEditEvidence_evidence-form-wrapper__3KijZ {
  width: 100%;
  display: flex;
  justify-content: center; }
  .ActionAddEditEvidence_evidence-form-wrapper__3KijZ .evidence-form {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 400px;
    margin-top: 43px; }
    .ActionAddEditEvidence_evidence-form-wrapper__3KijZ .evidence-form .form-item {
      margin-bottom: 26px; }
    .ActionAddEditEvidence_evidence-form-wrapper__3KijZ .evidence-form .evidence-level-text {
      padding-left: 0;
      font-weight: normal;
      font-size: 16px; }
    .ActionAddEditEvidence_evidence-form-wrapper__3KijZ .evidence-form label {
      display: flex;
      margin-bottom: 11px;
      font-weight: 600;
      font-size: 16px;
      color: #424d55; }
    .ActionAddEditEvidence_evidence-form-wrapper__3KijZ .evidence-form .divider {
      display: flex;
      width: 100%;
      height: 1px;
      margin: 45px 0;
      border: 1px solid #dae1e6; }
    .ActionAddEditEvidence_evidence-form-wrapper__3KijZ .evidence-form .form-item-input-text {
      font-size: 16px;
      line-height: 21px;
      height: 41px; }
    .ActionAddEditEvidence_evidence-form-wrapper__3KijZ .evidence-form textarea {
      resize: none !important;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #424d55; }
      .ActionAddEditEvidence_evidence-form-wrapper__3KijZ .evidence-form textarea.ant-input {
        height: 225px !important;
        max-height: 225px !important;
        overflow-y: scroll !important; }
    .ActionAddEditEvidence_evidence-form-wrapper__3KijZ .evidence-form .ant-btn {
      height: 44px !important; }
      .ActionAddEditEvidence_evidence-form-wrapper__3KijZ .evidence-form .ant-btn > span {
        width: 100%; }

.TableLevel_table-level-wrapper__1cIcl {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center; }

.VariantPage_variant-page-wrapper__35o3S {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  z-index: 0;
  width: 100%;
  height: calc(100vh - 48px); }
  .VariantPage_variant-page-wrapper__35o3S .links-wrapper {
    display: flex;
    height: 100%;
    z-index: 2;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    -webkit-transform: translate(-155px, 0px);
            transform: translate(-155px, 0px);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
        user-select: none; }
    .VariantPage_variant-page-wrapper__35o3S .links-wrapper.links-wrapper-open {
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px); }
  .VariantPage_variant-page-wrapper__35o3S .main-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    height: 100%;
    z-index: 0;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    -webkit-transform: translate(-155px, 0px);
            transform: translate(-155px, 0px);
    overflow-y: auto; }
    .VariantPage_variant-page-wrapper__35o3S .main-wrapper.links-wrapper-open {
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px); }
    .VariantPage_variant-page-wrapper__35o3S .main-wrapper .main-header-data {
      display: flex;
      height: 120px;
      background-color: #fff;
      box-shadow: 0 2px 3px 1px #dae1e6;
      z-index: 10; }
    .VariantPage_variant-page-wrapper__35o3S .main-wrapper .main-data {
      height: calc(100vh - 170px);
      justify-content: space-between; }
      .VariantPage_variant-page-wrapper__35o3S .main-wrapper .main-data .history {
        width: 100%;
        padding: 15px 60px;
        max-height: 455px;
        box-sizing: border-box; }
        .VariantPage_variant-page-wrapper__35o3S .main-wrapper .main-data .history.links-wrapper-open {
          width: calc(100% - 179px);
          -webkit-transition: 0.4s ease-in;
          transition: 0.4s ease-in; }
      .VariantPage_variant-page-wrapper__35o3S .main-wrapper .main-data .evidence {
        width: 100%;
        max-height: 455px;
        overflow: hidden;
        box-sizing: border-box;
        -webkit-transition: 0.3s ease-in;
        transition: 0.3s ease-in; }
        .VariantPage_variant-page-wrapper__35o3S .main-wrapper .main-data .evidence.links-wrapper-open {
          width: calc(100% - 179px);
          -webkit-transition: 0.4s ease-in;
          transition: 0.4s ease-in; }
      .VariantPage_variant-page-wrapper__35o3S .main-wrapper .main-data .conflict-notifications {
        width: calc(100vw - 25px); }
        .VariantPage_variant-page-wrapper__35o3S .main-wrapper .main-data .conflict-notifications.links-wrapper-open {
          width: calc(100vw - 196px);
          -webkit-transition: 0.4s ease-in;
          transition: 0.4s ease-in; }

.VariantPage_section-toolbar__31bGd {
  display: flex;
  align-items: center;
  margin-bottom: 12px; }
  .VariantPage_section-toolbar__31bGd .classification-history-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #424d55;
    text-transform: uppercase; }
  .VariantPage_section-toolbar__31bGd .toolbar-select {
    width: 200px;
    margin-left: 10px; }
    .VariantPage_section-toolbar__31bGd .toolbar-select .ant-select {
      width: 100%; }

.VariantPageHeader_variant-page-header-wrapper__7Njuj {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease; }
  .VariantPageHeader_variant-page-header-wrapper__7Njuj .left-data-wrapper {
    height: 100%; }
    .VariantPageHeader_variant-page-header-wrapper__7Njuj .left-data-wrapper .left-data {
      display: grid;
      width: 100%;
      grid-template-columns: minmax(150px, 200px) minmax(150px, 200px) minmax(150px, 200px);
      grid-auto-rows: minmax(auto, 48%);
      grid-gap: 5px;
      height: 100%;
      padding: 40px 5px 30px 40px; }
      .VariantPageHeader_variant-page-header-wrapper__7Njuj .left-data-wrapper .left-data .gene {
        padding: 0 10px; }
        .VariantPageHeader_variant-page-header-wrapper__7Njuj .left-data-wrapper .left-data .gene.gene-external-link {
          padding: 0 10px; }
    .VariantPageHeader_variant-page-header-wrapper__7Njuj .left-data-wrapper .cell {
      padding: 0 10px; }
      .VariantPageHeader_variant-page-header-wrapper__7Njuj .left-data-wrapper .cell.cell-external-link {
        padding: 0 10px; }
        .VariantPageHeader_variant-page-header-wrapper__7Njuj .left-data-wrapper .cell.cell-external-link .external-link-text {
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.6);
          text-transform: uppercase; }
  .VariantPageHeader_variant-page-header-wrapper__7Njuj .right-data {
    flex: 1 1 300px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease; }
    .VariantPageHeader_variant-page-header-wrapper__7Njuj .right-data .right-data-conflict-message {
      margin-top: 10px; }
    .VariantPageHeader_variant-page-header-wrapper__7Njuj .right-data.sidebar-open {
      -webkit-transform: translate(-175px, 0px);
              transform: translate(-175px, 0px); }

.InformField_inform-field-wrapper__HP8jF {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%; }
  .InformField_inform-field-wrapper__HP8jF .icon {
    width: auto; }
  .InformField_inform-field-wrapper__HP8jF label {
    font-weight: normal;
    font-size: 16px;
    color: #96a2aa;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content; }
  .InformField_inform-field-wrapper__HP8jF .text {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 4px; }
    .InformField_inform-field-wrapper__HP8jF .text.cut {
      width: 112px; }

.VariantClassificationContainer_zygosity-type-wrapper__eYcXt {
  display: flex;
  justify-content: right;
  flex-direction: column;
  width: 295px;
  height: 100%;
  padding-right: 55px;
  align-items: center;
  padding-top: 14px;
  cursor: pointer; }
  .VariantClassificationContainer_zygosity-type-wrapper__eYcXt .current-zygosity-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%; }
    .VariantClassificationContainer_zygosity-type-wrapper__eYcXt .current-zygosity-wrapper .title {
      font-size: 10px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.3); }
    .VariantClassificationContainer_zygosity-type-wrapper__eYcXt .current-zygosity-wrapper .context {
      font-size: 10px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.3);
      padding-left: 5px; }
  .VariantClassificationContainer_zygosity-type-wrapper__eYcXt .zygosity-type-radio-group {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 8px;
    flex-direction: row; }
    .VariantClassificationContainer_zygosity-type-wrapper__eYcXt .zygosity-type-radio-group .reconfirm-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      width: 62%;
      height: 30px; }
      .VariantClassificationContainer_zygosity-type-wrapper__eYcXt .zygosity-type-radio-group .reconfirm-wrapper .reconfirm-labeled-tag {
        font-size: 12px;
        font-weight: 600;
        text-align: left; }
      .VariantClassificationContainer_zygosity-type-wrapper__eYcXt .zygosity-type-radio-group .reconfirm-wrapper .reconfirm-text {
        font-size: 12px;
        width: 100%;
        text-align: left; }
    .VariantClassificationContainer_zygosity-type-wrapper__eYcXt .zygosity-type-radio-group .label-text {
      text-transform: uppercase; }

.NonActiveButton_select-non-active-wrapper__3w9bG {
  display: flex;
  position: relative; }
  .NonActiveButton_select-non-active-wrapper__3w9bG .select-non-active {
    width: 120px;
    height: 60px;
    background: #f6f6f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0px;
    border: 1px solid rgba(0, 0, 0, 0.1); }
    .NonActiveButton_select-non-active-wrapper__3w9bG .select-non-active.active {
      background-color: #0e8ce6; }
    .NonActiveButton_select-non-active-wrapper__3w9bG .select-non-active .select-non-active-title {
      margin-top: 10px;
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
      color: #96a2aa; }
      .NonActiveButton_select-non-active-wrapper__3w9bG .select-non-active .select-non-active-title.active {
        color: #fff; }
    .NonActiveButton_select-non-active-wrapper__3w9bG .select-non-active .select-non-active-class {
      display: flex;
      justify-content: center;
      align-items: baseline; }
      .NonActiveButton_select-non-active-wrapper__3w9bG .select-non-active .select-non-active-class .label-custom-style {
        font-weight: 600;
        font-size: 22px;
        line-height: 100%;
        color: #96a2aa; }
        .NonActiveButton_select-non-active-wrapper__3w9bG .select-non-active .select-non-active-class .label-custom-style.active {
          color: #fff; }
      .NonActiveButton_select-non-active-wrapper__3w9bG .select-non-active .select-non-active-class span {
        font-weight: 600;
        font-size: 22px;
        line-height: 100%;
        color: #96a2aa; }
        .NonActiveButton_select-non-active-wrapper__3w9bG .select-non-active .select-non-active-class span.active {
          color: #fff; }
      .NonActiveButton_select-non-active-wrapper__3w9bG .select-non-active .select-non-active-class .label-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #96a2aa; }
  .NonActiveButton_select-non-active-wrapper__3w9bG .select-non-active.active .label-text {
    color: #fff !important; }

.ZygosityTypeButton_zygosity-type-button-wrapper__FY0ed .zygosity-type-button {
  position: relative;
  border-radius: 0px;
  background-color: #0e8ce6 !important;
  cursor: pointer; }
  .ZygosityTypeButton_zygosity-type-button-wrapper__FY0ed .zygosity-type-button .select-title {
    display: block;
    position: absolute;
    right: 34%;
    top: 10px;
    font-weight: 300;
    font-size: 10px;
    line-height: 16px;
    color: #fff; }
    .ZygosityTypeButton_zygosity-type-button-wrapper__FY0ed .zygosity-type-button .select-title.active {
      background: none !important;
      color: #96a2aa; }
  .ZygosityTypeButton_zygosity-type-button-wrapper__FY0ed .zygosity-type-button .ant-select .ant-select-arrow svg {
    -webkit-transform: none !important;
            transform: none !important; }
  .ZygosityTypeButton_zygosity-type-button-wrapper__FY0ed .zygosity-type-button .ant-select .ant-select-arrow:hover {
    background: none; }
  .ZygosityTypeButton_zygosity-type-button-wrapper__FY0ed .zygosity-type-button .ant-select .ant-select-selection-selected-value .reconfirm {
    display: none; }
  .ZygosityTypeButton_zygosity-type-button-wrapper__FY0ed .zygosity-type-button .select-header-text-style {
    color: #fff !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px; }
    .ZygosityTypeButton_zygosity-type-button-wrapper__FY0ed .zygosity-type-button .select-header-text-style.active {
      color: #96a2aa !important; }
  .ZygosityTypeButton_zygosity-type-button-wrapper__FY0ed .zygosity-type-button.active {
    background: #f6f6f6 !important;
    color: #96a2aa; }
    .ZygosityTypeButton_zygosity-type-button-wrapper__FY0ed .zygosity-type-button.active svg path {
      fill: #96a2aa !important; }
  .ZygosityTypeButton_zygosity-type-button-wrapper__FY0ed .zygosity-type-button.active .ant-select-selection-selected-value {
    color: #96a2aa; }

.VariantConflictsUsedNotifications_variant-conflicts-used-notifications__PGlv_ .variant-conflicts-used-item {
  display: flex;
  align-items: center; }

.VariantConflictsUsedNotifications_variant-conflicts-used-notifications__PGlv_ .conflict-icon {
  width: 29px;
  height: 29px;
  min-width: 29px;
  min-height: 29px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat; }

.VariantConflictsUsedNotifications_variant-conflicts-used-notifications__PGlv_ .conflict-message {
  font-size: 14px;
  color: black;
  margin-left: 5px;
  margin-right: 5px;
  text-align: left; }
  .VariantConflictsUsedNotifications_variant-conflicts-used-notifications__PGlv_ .conflict-message span {
    font-weight: 600; }

.ExternalResources_external-resources-wrapper__yRiko .external-resources {
  width: 167px;
  padding-left: 6px; }
  .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-header .external-resources-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #424d55;
    margin: 14px 0 9px; }
  .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-header .divider {
    border-bottom: 1px solid #dae1e6; }
  .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-part {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-part .external-resources-part-title {
      margin: 12px 0 0 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: #96a2aa; }
    .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-part ul {
      padding-left: 0;
      text-align: left;
      list-style: none; }
      .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-part ul li {
        margin: 10px 0 0 0; }
        .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-part ul li a {
          font-weight: 600;
          font-size: 14px;
          color: #424d55;
          line-height: 18px; }
          .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-part ul li a:hover {
            color: #778690; }
        .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-part ul li .text-not-link {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          width: 100%; }
          .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-part ul li .text-not-link .text-not-link-title {
            flex: 3 1;
            font-weight: 600;
            font-size: 14px;
            color: #424d55;
            line-height: 18px; }
          .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-part ul li .text-not-link .text-not-link-value {
            flex: 3 1;
            color: #778690;
            width: 20%;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
            .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-part ul li .text-not-link .text-not-link-value span {
              cursor: default; }
          .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-part ul li .text-not-link .external-resources-title {
            padding-right: 7px;
            font-weight: 600;
            font-size: 14px;
            color: #424d55;
            line-height: 18px; }
          .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-part ul li .text-not-link .external-resources-array {
            display: flex;
            flex-direction: column;
            justify-content: flex-start; }
            .ExternalResources_external-resources-wrapper__yRiko .external-resources .external-resources-part ul li .text-not-link .external-resources-array a {
              margin-bottom: 6px; }

.ClassificationHistoryTable_classification-history-wrapper__1NfBN {
  width: 100%;
  height: auto;
  overflow: hidden !important; }
  .ClassificationHistoryTable_classification-history-wrapper__1NfBN .empty-state {
    height: 177px; }
  .ClassificationHistoryTable_classification-history-wrapper__1NfBN .ant-table-wrapper {
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    width: 100%; }
    .ClassificationHistoryTable_classification-history-wrapper__1NfBN .ant-table-wrapper.classification-history-table-wrapper .ant-table-content .ant-table-scroll {
      overflow: hidden !important;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease; }
      .ClassificationHistoryTable_classification-history-wrapper__1NfBN .ant-table-wrapper.classification-history-table-wrapper .ant-table-content .ant-table-scroll .ant-table-body {
        max-height: 255px !important;
        overflow-y: scroll !important;
        overflow-x: hidden !important; }
    .ClassificationHistoryTable_classification-history-wrapper__1NfBN .ant-table-wrapper.classification-history-table-wrapper.with-scroll {
      height: 300px;
      max-height: 300px !important;
      box-shadow: inset 0 -91px 40px -110px rgba(0, 0, 0, 0.17); }
    .ClassificationHistoryTable_classification-history-wrapper__1NfBN .ant-table-wrapper .with-scroll {
      overflow-y: scroll !important; }
  .ClassificationHistoryTable_classification-history-wrapper__1NfBN .ant-table-thead > tr > th {
    background-color: white;
    color: rgba(0, 0, 0, 0.3);
    font-weight: bold;
    font-size: 12px !important;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding: 10px 15px; }
  .ClassificationHistoryTable_classification-history-wrapper__1NfBN .n-table-cell {
    padding: 10px 15px;
    font-size: 14px; }
  .ClassificationHistoryTable_classification-history-wrapper__1NfBN .see-all-btn {
    display: flex;
    justify-content: center; }
    .ClassificationHistoryTable_classification-history-wrapper__1NfBN .see-all-btn > div {
      width: 100px; }
      .ClassificationHistoryTable_classification-history-wrapper__1NfBN .see-all-btn > div .label {
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase; }
      .ClassificationHistoryTable_classification-history-wrapper__1NfBN .see-all-btn > div .icon {
        display: none; }

.ToggledButton_toggled-button-wrapper__1xVRT {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: row;
  cursor: pointer;
  margin: 15px 0; }
  .ToggledButton_toggled-button-wrapper__1xVRT .label {
    font-weight: 600;
    font-size: 16px;
    color: #0e8ce6; }
  .ToggledButton_toggled-button-wrapper__1xVRT .icon {
    margin-left: 5px; }
    .ToggledButton_toggled-button-wrapper__1xVRT .icon svg path {
      fill: #0e8ce6; }

.TableDateAndUser_table-date-and-user-wrapper__a8hpF {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start; }
  .TableDateAndUser_table-date-and-user-wrapper__a8hpF .table-date-and-user {
    display: flex;
    width: 80%;
    flex-direction: column; }
    .TableDateAndUser_table-date-and-user-wrapper__a8hpF .table-date-and-user .date {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #424d55;
      margin-bottom: 4px; }
    .TableDateAndUser_table-date-and-user-wrapper__a8hpF .table-date-and-user .user {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #0e8ce6;
      cursor: pointer; }

.EvidenceContainer_evidence-wrapper__20e1b {
  width: 100%;
  overflow: hidden !important; }
  .EvidenceContainer_evidence-wrapper__20e1b .add-evidence-button-text {
    display: flex;
    width: 100%;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #fff;
    height: 32px !important;
    padding: 0 10px !important; }
  .EvidenceContainer_evidence-wrapper__20e1b .section-toolbar {
    height: 41px;
    display: flex;
    align-items: center;
    padding: 0 60px; }
    .EvidenceContainer_evidence-wrapper__20e1b .section-toolbar .evidence-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase; }
    .EvidenceContainer_evidence-wrapper__20e1b .section-toolbar .toolbar-select {
      width: 200px;
      margin-left: 10px; }
      .EvidenceContainer_evidence-wrapper__20e1b .section-toolbar .toolbar-select .ant-select {
        width: 100%; }
  .EvidenceContainer_evidence-wrapper__20e1b .ant-tabs {
    text-align: left; }
    .EvidenceContainer_evidence-wrapper__20e1b .ant-tabs .ant-tabs-ink-bar-animated {
      height: 2px !important; }
    .EvidenceContainer_evidence-wrapper__20e1b .ant-tabs .ant-tabs-tab {
      padding: 27px 0 15px 0 !important;
      margin: 0 60px 0 0 !important; }
    .EvidenceContainer_evidence-wrapper__20e1b .ant-tabs .ant-tabs-bar {
      padding: 0 60px 0 50px;
      border-bottom: none;
      position: relative; }
      .EvidenceContainer_evidence-wrapper__20e1b .ant-tabs .ant-tabs-bar:after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 60px;
        right: 60px;
        height: 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.0984771); }
    .EvidenceContainer_evidence-wrapper__20e1b .ant-tabs .ant-tabs-extra-content {
      -webkit-transition: 0.4s ease 0s;
      transition: 0.4s ease 0s; }
    .EvidenceContainer_evidence-wrapper__20e1b .ant-tabs .ant-tabs-extra-content {
      margin: 10px 0 0 20px; }
    .EvidenceContainer_evidence-wrapper__20e1b .ant-tabs .ant-tabs-tabpane {
      overflow: auto; }
  .EvidenceContainer_evidence-wrapper__20e1b .ant-tabs .ant-tabs-top-content,
  .EvidenceContainer_evidence-wrapper__20e1b .ant-tabs .ant-tabs-bottom-content {
    height: 300px; }
  .EvidenceContainer_evidence-wrapper__20e1b .empty-state {
    height: 177px; }

.TabPaneHeader_tab-pane-content-wrapper__2jei4 {
  width: 100%;
  height: auto;
  position: relative; }
  .TabPaneHeader_tab-pane-content-wrapper__2jei4 .title {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    line-height: 16px; }
  .TabPaneHeader_tab-pane-content-wrapper__2jei4 .amount {
    background-color: #23d46a;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    position: absolute;
    right: -14px;
    top: -7px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 16px;
    color: white; }

.EvidenceTable_new-table__2CsrD .ant-table-fixed {
  table-layout: fixed !important; }

.EvidenceTable_new-table__2CsrD .ant-table-empty .ant-table-body {
  overflow-y: hidden !important; }

.EvidenceTable_new-table__2CsrD .ant-table-thead > tr > th {
  height: 64px;
  background-color: #f6f6f6;
  font-size: 14px !important;
  padding: 15px; }
  .EvidenceTable_new-table__2CsrD .ant-table-thead > tr > th:first-child {
    padding-left: 60px; }
  .EvidenceTable_new-table__2CsrD .ant-table-thead > tr > th:last-child {
    padding-right: 60px; }

.EvidenceTable_new-table__2CsrD .ant-table-tbody > tr > td:first-child {
  padding-left: 45px; }

.EvidenceTable_new-table__2CsrD .ant-table-tbody > tr > td:last-child {
  padding-right: 45px; }

.EvidenceTable_new-table__2CsrD .multiLine-text {
  height: 80%;
  font-size: 14px !important; }

.EvidenceTable_new-table__2CsrD .label-tag .label-text {
  font-size: 12px;
  font-weight: bold;
  color: #424d55; }

.EvidenceTable_new-table__2CsrD .ok-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .EvidenceTable_new-table__2CsrD .ok-icon svg {
    width: 18px;
    height: 11px; }
    .EvidenceTable_new-table__2CsrD .ok-icon svg path {
      stroke: #424d55; }

.EvidenceTable_new-table__2CsrD .delete-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer; }
  .EvidenceTable_new-table__2CsrD .delete-icon svg {
    width: 15px;
    height: 15px; }
    .EvidenceTable_new-table__2CsrD .delete-icon svg:hover {
      border-radius: 4px; }
      .EvidenceTable_new-table__2CsrD .delete-icon svg:hover g path {
        fill: #424d55; }

.EvidenceTable_new-table__2CsrD .n-table-cell {
  padding: 10px 15px;
  font-size: 14px; }

.EvidenceTable_evidence-table-wrapper__2qrJK {
  width: 100%;
  height: auto;
  overflow: hidden !important; }

.TableSourceDescription_table-source-description-wrapper__1up7v {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center; }
  .TableSourceDescription_table-source-description-wrapper__1up7v .table-source-description {
    display: flex;
    flex-direction: column; }
    .TableSourceDescription_table-source-description-wrapper__1up7v .table-source-description .source {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 4px; }
      .TableSourceDescription_table-source-description-wrapper__1up7v .table-source-description .source.link {
        color: #0e8ce6;
        cursor: pointer; }
    .TableSourceDescription_table-source-description-wrapper__1up7v .table-source-description .description {
      max-width: 750px;
      word-wrap: break-word;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #424d55; }

.TableMatch_table-match-wrapper__-6rRR {
  padding: 0 16px; }

.TableActions_table-actions-wrapper__wswQO {
  width: 100%;
  height: 100%; }
  .TableActions_table-actions-wrapper__wswQO .table-actions {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center; }
    .TableActions_table-actions-wrapper__wswQO .table-actions .edit {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px 0 0; }
      .TableActions_table-actions-wrapper__wswQO .table-actions .edit svg {
        width: 30px;
        height: 30px; }
      .TableActions_table-actions-wrapper__wswQO .table-actions .edit:hover {
        border-radius: 4px;
        background-color: #0e8ce6; }
        .TableActions_table-actions-wrapper__wswQO .table-actions .edit:hover svg path {
          fill: #fff; }
    .TableActions_table-actions-wrapper__wswQO .table-actions .delete {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      cursor: pointer; }
      .TableActions_table-actions-wrapper__wswQO .table-actions .delete svg {
        width: 20px;
        height: 20px; }
      .TableActions_table-actions-wrapper__wswQO .table-actions .delete:hover {
        border-radius: 4px;
        background-color: #f11e2c; }
        .TableActions_table-actions-wrapper__wswQO .table-actions .delete:hover svg g {
          opacity: 1; }
          .TableActions_table-actions-wrapper__wswQO .table-actions .delete:hover svg g path {
            fill: #fff; }

.ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 {
  width: 610px;
  height: 350px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden; }
  .ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 .header {
    background-color: #e7eef2;
    padding: 0 25px;
    height: 64px;
    display: flex;
    justify-content: left;
    align-items: center; }
    .ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 .header .header-title {
      font-weight: 600;
      font-size: 22px;
      color: #424d55;
      display: flex;
      align-items: center; }
    .ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 .header .delete-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px 3px 0; }
      .ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 .header .delete-icon svg {
        width: 20px;
        height: 20px; }
        .ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 .header .delete-icon svg g {
          opacity: 1; }
          .ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 .header .delete-icon svg g path {
            fill: #f73f3f; }
  .ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 .body-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 285px;
    padding: 24px 47px 32px 36px; }
    .ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 .body-content-wrapper .body-content {
      width: 100%;
      height: 100%;
      font-weight: normal;
      font-size: 22px;
      line-height: 30px;
      color: #424d55; }
      .ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 .body-content-wrapper .body-content span {
        font-weight: bold; }
  .ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 .content-footer .buttons {
    display: flex;
    justify-content: flex-end; }
    .ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 .content-footer .buttons button {
      display: flex;
      justify-content: center;
      width: 84px;
      height: 33px;
      border: none;
      box-shadow: none !important; }
      .ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 .content-footer .buttons button .buttons-text {
        font-weight: normal;
        font-size: 18px;
        text-align: center;
        color: #424d55; }
      .ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 .content-footer .buttons button:hover {
        background-color: #f73f3f; }
        .ActionDeleteEvidence_action-delete-evidence-wrapper__1nvO6 .content-footer .buttons button:hover .buttons-text {
          color: #fff; }

.SimpleButton_simple-button-wrapper__2d51P button {
  width: 150px;
  height: 33px;
  background-color: #0e8ce6;
  color: white;
  font-size: 12px !important;
  text-transform: uppercase; }
  .SimpleButton_simple-button-wrapper__2d51P button:hover, .SimpleButton_simple-button-wrapper__2d51P button:focus, .SimpleButton_simple-button-wrapper__2d51P button:active {
    background-color: #1378c0;
    color: white; }
  .SimpleButton_simple-button-wrapper__2d51P button.light {
    background-color: white;
    color: #2196f3; }
    .SimpleButton_simple-button-wrapper__2d51P button.light:hover, .SimpleButton_simple-button-wrapper__2d51P button.light:focus, .SimpleButton_simple-button-wrapper__2d51P button.light:active {
      background-color: white;
      color: #2196f3; }

.SimpleButton_simple-button-wrapper__2d51P .ant-btn {
  width: 100%;
  height: 100%; }


.VariantConflictsPendingClassificationItem_variant-conflict-pending-classification-item__qJ6Ef {
  display: flex;
  justify-content: flex-end; }
  .VariantConflictsPendingClassificationItem_variant-conflict-pending-classification-item__qJ6Ef .conflict-icon {
    width: 29px;
    height: 29px;
    min-width: 29px;
    min-height: 29px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; }
  .VariantConflictsPendingClassificationItem_variant-conflict-pending-classification-item__qJ6Ef .pending-classification-wrapped {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 43px 0 0 43px;
    height: 43px;
    padding: 0 35px 0 16px;
    margin-top: 14px;
    cursor: pointer; }
    .VariantConflictsPendingClassificationItem_variant-conflict-pending-classification-item__qJ6Ef .pending-classification-wrapped .conflict-icon {
      margin-right: 12px; }
    .VariantConflictsPendingClassificationItem_variant-conflict-pending-classification-item__qJ6Ef .pending-classification-wrapped .wrapped-conflict-text {
      display: flex;
      font-size: 16px;
      color: #424d55;
      white-space: nowrap; }
    .VariantConflictsPendingClassificationItem_variant-conflict-pending-classification-item__qJ6Ef .pending-classification-wrapped span {
      margin-right: 10px; }
  .VariantConflictsPendingClassificationItem_variant-conflict-pending-classification-item__qJ6Ef .pending-classification-unwrapped {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 30px 35px 30px 58px;
    background-color: #f6fcff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    font-size: 18px; }
    .VariantConflictsPendingClassificationItem_variant-conflict-pending-classification-item__qJ6Ef .pending-classification-unwrapped > * {
      margin-right: 85px; }
    .VariantConflictsPendingClassificationItem_variant-conflict-pending-classification-item__qJ6Ef .pending-classification-unwrapped .conflict-title {
      font-weight: 600; }
    .VariantConflictsPendingClassificationItem_variant-conflict-pending-classification-item__qJ6Ef .pending-classification-unwrapped .conlict-wrap-btn {
      width: 10px;
      height: 6px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
      border: none;
      position: absolute;
      top: 50%;
      right: 35px;
      margin-right: 0;
      -webkit-transform: translateY(-50%) rotate(180deg);
              transform: translateY(-50%) rotate(180deg); }

.TestsPage_wrapper__1SwAD {
  background-color: #e5e5e5;
  width: 100vw;
  height: 100vh;
  padding: 32px 40px;
  overflow: auto; }

.TestsPage_row__32seu {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  padding: 24px 32px; }

.TestsPage_row-section-gsid__EK_kH {
  width: 285px; }

.TestsPage_row-section-panel-type__2LMLX {
  width: 285px; }

.TestsPage_row-section-links__1-z-f {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center; }

.TestsPage_row-section-title__hRQa9 {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #222222;
  margin-bottom: 27px; }

.TestsPage_row-section-value__FxgCl {
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.6); }

.TestsPage_link__2aYtQ {
  display: flex;
  align-items: center; }

.TestsPage_link-icon__DlXXV {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 3px solid #c4c4c4;
  margin-right: 16px; }

.TestsPage_link-icon-locked__2q-zP {
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }

.TestsPage_link-link__3qJ5Y {
  font-size: 14px;
  line-height: 18px;
  color: #222222;
  text-decoration: underline; }
  .TestsPage_link-link__3qJ5Y:hover {
    color: #222222; }

.TestsPage_link-div-line__1FpwQ {
  width: 120px;
  height: 0px;
  border-top: 2px dashed #3ebf79;
  margin: 0 24px; }

.ConfirmationPage_confirmation-page-wrapper__1Oqy4 {
  height: calc(100vh - 48px); }
  .ConfirmationPage_confirmation-page-wrapper__1Oqy4 .confirmation-content {
    height: 100%; }
    .ConfirmationPage_confirmation-page-wrapper__1Oqy4 .confirmation-content .confirmation-table {
      height: 100%; }

.ConfirmationLabTable_table-wrapper__1kbxt .activity-icon-wrapper {
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 29px;
  padding-top: 3px; }
  .ConfirmationLabTable_table-wrapper__1kbxt .activity-icon-wrapper:active, .ConfirmationLabTable_table-wrapper__1kbxt .activity-icon-wrapper:hover {
    background: #0e8ce6;
    border-radius: 4px; }
    .ConfirmationLabTable_table-wrapper__1kbxt .activity-icon-wrapper:active path, .ConfirmationLabTable_table-wrapper__1kbxt .activity-icon-wrapper:hover path {
      fill: #fff; }

.ConfirmationLabTable_table-wrapper__1kbxt .activity-icon-wrapper.disabled {
  cursor: not-allowed; }
  .ConfirmationLabTable_table-wrapper__1kbxt .activity-icon-wrapper.disabled svg {
    opacity: 0.5; }

.ConfirmationLabTable_table-wrapper__1kbxt .activity-log-details {
  position: absolute;
  z-index: 9;
  right: 24px;
  top: -8px;
  min-width: 320px;
  max-height: 121px;
  overflow-y: -webkit-paged-y;
  overflow-y: overlay;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  background: #fff;
  padding: 0 20px 0 5px; }
  .ConfirmationLabTable_table-wrapper__1kbxt .activity-log-details .record {
    border-bottom: 1px solid #dae1e6;
    background: #fff; }
    .ConfirmationLabTable_table-wrapper__1kbxt .activity-log-details .record svg {
      height: 27px; }
    .ConfirmationLabTable_table-wrapper__1kbxt .activity-log-details .record .record__user {
      min-width: 84px;
      border-right: 1px solid #dae1e6; }
    .ConfirmationLabTable_table-wrapper__1kbxt .activity-log-details .record .record_changed-item {
      width: 100px;
      text-align: center;
      border-right: 1px solid #dae1e6; }
      .ConfirmationLabTable_table-wrapper__1kbxt .activity-log-details .record .record_changed-item .notes {
        display: block;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        padding: 0 6px; }
    .ConfirmationLabTable_table-wrapper__1kbxt .activity-log-details .record .record_time {
      min-width: 160px;
      text-align: center;
      font-size: 14px;
      color: #96a2aa; }
  .ConfirmationLabTable_table-wrapper__1kbxt .activity-log-details .record__item {
    margin: 10px 0;
    height: 18px; }
    .ConfirmationLabTable_table-wrapper__1kbxt .activity-log-details .record__item .user-name {
      margin-left: 11px;
      margin-right: 11px; }

.ConfirmationLabTable_table-wrapper__1kbxt .sorter {
  padding: 0; }
  .ConfirmationLabTable_table-wrapper__1kbxt .sorter .ant-table-header-column {
    width: 100%; }

.ConfirmationLabTable_table-wrapper__1kbxt .table-sorter-wrapper {
  cursor: pointer;
  min-height: 53px; }
  .ConfirmationLabTable_table-wrapper__1kbxt .table-sorter-wrapper:hover {
    background: #e7e7e7; }
  .ConfirmationLabTable_table-wrapper__1kbxt .table-sorter-wrapper svg {
    cursor: pointer;
    opacity: 0.5;
    width: 9px;
    height: 5px; }
    .ConfirmationLabTable_table-wrapper__1kbxt .table-sorter-wrapper svg:hover {
      opacity: 1; }
  .ConfirmationLabTable_table-wrapper__1kbxt .table-sorter-wrapper .arrow-active {
    opacity: 1; }
  .ConfirmationLabTable_table-wrapper__1kbxt .table-sorter-wrapper .top-arrow {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    margin-bottom: 2px; }
  .ConfirmationLabTable_table-wrapper__1kbxt .table-sorter-wrapper .table-sorter-title {
    margin-right: 6px; }

.ConfirmationLabTable_table-wrapper__1kbxt .table-select-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; }
  .ConfirmationLabTable_table-wrapper__1kbxt .table-select-wrapper .ant-select-selection__rendered {
    margin-left: 0 !important; }

.ConfirmationLabTable_table-wrapper__1kbxt .priority {
  padding-right: 7px; }

.ConfirmationLabTable_table-wrapper__1kbxt .ant-table-fixed {
  table-layout: fixed !important; }

.ConfirmationLabTable_table-wrapper__1kbxt .text {
  width: 70%;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #424d55;
  margin-left: 4px; }
  .ConfirmationLabTable_table-wrapper__1kbxt .text.cut {
    width: 65%;
    max-width: 110px; }

.ConfirmationLabTable_table-wrapper__1kbxt .numeric {
  text-align: right; }
  .ConfirmationLabTable_table-wrapper__1kbxt .numeric .inner {
    text-align: right;
    justify-content: flex-end; }

.ConfirmationLabTable_table-wrapper__1kbxt .hidden-classification {
  opacity: 0.3; }

.ConfirmationLabTable_table-wrapper__1kbxt .ant-table-content {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  max-height: calc(100vh - 48px); }
  .ConfirmationLabTable_table-wrapper__1kbxt .ant-table-content .ant-table-scroll {
    height: calc(100vh - 48px);
    overflow-x: auto !important; }
    .ConfirmationLabTable_table-wrapper__1kbxt .ant-table-content .ant-table-scroll .ant-table-body {
      height: calc(100vh - 106px) !important;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease; }
    .ConfirmationLabTable_table-wrapper__1kbxt .ant-table-content .ant-table-scroll table {
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
      -webkit-transform: translate(0px, 0px);
              transform: translate(0px, 0px);
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
  .ConfirmationLabTable_table-wrapper__1kbxt .ant-table-content .ant-table-fixed-left {
    height: calc(100vh - 171px);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; }
  .ConfirmationLabTable_table-wrapper__1kbxt .ant-table-content .ant-table-body-inner {
    height: calc(100vh - 106px) !important; }

.Login_login-wrapper__1y9ZZ .ant-form.ant-form-vertical {
  position: fixed;
  top: 40%;
  padding: 48px 64px 30px 64px;
  background: #fff; }
  .Login_login-wrapper__1y9ZZ .ant-form.ant-form-vertical .ant-row.ant-form-item {
    width: 464px;
    margin-bottom: 18px; }
  .Login_login-wrapper__1y9ZZ .ant-form.ant-form-vertical .form-item-title {
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    margin-bottom: 5px; }

.FinalReportPage_final-report-page-wrapper__8KQaw {
  height: calc(100vh - 48px);
  overflow: auto;
  background-color: #e5e5e5; }
  .FinalReportPage_final-report-page-wrapper__8KQaw .final-report-main-content {
    padding: 25px 40px; }
  .FinalReportPage_final-report-page-wrapper__8KQaw .final-report-header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px; }
  .FinalReportPage_final-report-page-wrapper__8KQaw .final-report-back-link {
    margin-right: 26px;
    color: rgba(0, 0, 0, 0.6); }
  .FinalReportPage_final-report-page-wrapper__8KQaw .final-report-main-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #424d55;
    margin-bottom: 0; }
  .FinalReportPage_final-report-page-wrapper__8KQaw .final-report-body-content {
    display: flex;
    justify-content: space-between; }
  .FinalReportPage_final-report-page-wrapper__8KQaw .final-report-sidebar {
    width: 320px;
    min-width: 320px;
    margin-left: 33px; }
  .FinalReportPage_final-report-page-wrapper__8KQaw .final-report-section {
    width: calc(100% - 353px);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #fff; }
  .FinalReportPage_final-report-page-wrapper__8KQaw .final-report-section-title {
    padding: 33px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #0e8ce6; }

.FinalReportActionableTable_new-table__1zYww .ant-table-fixed {
  table-layout: fixed !important; }

.FinalReportActionableTable_new-table__1zYww .ant-table-empty .ant-table-body {
  overflow-y: hidden !important; }

.FinalReportActionableTable_new-table__1zYww .ant-table-thead > tr > th {
  height: 64px;
  background-color: #f6f6f6;
  font-size: 14px !important;
  padding: 15px; }

.FinalReportActionableTable_new-table__1zYww .multiLine-text {
  height: 80%;
  font-size: 14px !important; }

.FinalReportActionableTable_new-table__1zYww .label-tag .label-text {
  font-size: 12px;
  font-weight: bold;
  color: #424d55; }

.FinalReportActionableTable_new-table__1zYww .ok-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .FinalReportActionableTable_new-table__1zYww .ok-icon svg {
    width: 18px;
    height: 11px; }
    .FinalReportActionableTable_new-table__1zYww .ok-icon svg path {
      stroke: #424d55; }

.FinalReportActionableTable_new-table__1zYww .delete-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer; }
  .FinalReportActionableTable_new-table__1zYww .delete-icon svg {
    width: 15px;
    height: 15px; }
    .FinalReportActionableTable_new-table__1zYww .delete-icon svg:hover {
      border-radius: 4px; }
      .FinalReportActionableTable_new-table__1zYww .delete-icon svg:hover g path {
        fill: #424d55; }

.FinalReportActionableTable_new-table__1zYww .n-table-cell {
  padding: 0 15px;
  font-size: 14px; }

.MultiLineText_multiLine-text-wrapper__3owxf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto; }
  .MultiLineText_multiLine-text-wrapper__3owxf .multiLine-text {
    width: 70%;
    max-width: 110px;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #424d55;
    margin-left: 4px; }

.ActionableDetailsTabPaneHeader_tab-pane-content-wrapper__3KekF {
  width: 100%;
  height: auto; }
  .ActionableDetailsTabPaneHeader_tab-pane-content-wrapper__3KekF .title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #222222; }
  .ActionableDetailsTabPaneHeader_tab-pane-content-wrapper__3KekF .amount {
    font-weight: bold;
    font-size: 14px;
    color: #0e8ce6; }

.ExpandedInterpretation_expanded-interpretation-wrapper__1GQn_ {
  display: flex;
  width: 100%;
  height: auto;
  overflow-y: scroll;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px; }
  .ExpandedInterpretation_expanded-interpretation-wrapper__1GQn_ .textarea-wrapper-label {
    font-size: 10px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.3); }
  .ExpandedInterpretation_expanded-interpretation-wrapper__1GQn_ .textarea-wrapper {
    position: relative;
    min-width: 300px;
    min-height: 300px;
    width: 600px;
    margin: 0 26px 0 0; }
    .ExpandedInterpretation_expanded-interpretation-wrapper__1GQn_ .textarea-wrapper .icon-saved {
      position: absolute;
      z-index: 9999;
      right: 14px;
      bottom: 52px; }
      .ExpandedInterpretation_expanded-interpretation-wrapper__1GQn_ .textarea-wrapper .icon-saved .ok-icon {
        width: 100%;
        height: 100%;
        display: none;
        justify-content: center;
        align-items: center; }
        .ExpandedInterpretation_expanded-interpretation-wrapper__1GQn_ .textarea-wrapper .icon-saved .ok-icon svg {
          width: 28px;
          height: 22px; }
          .ExpandedInterpretation_expanded-interpretation-wrapper__1GQn_ .textarea-wrapper .icon-saved .ok-icon svg path {
            stroke: #22b36e; }
        .ExpandedInterpretation_expanded-interpretation-wrapper__1GQn_ .textarea-wrapper .icon-saved .ok-icon.active {
          display: flex; }
    .ExpandedInterpretation_expanded-interpretation-wrapper__1GQn_ .textarea-wrapper textarea {
      resize: none !important;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #383838; }
      .ExpandedInterpretation_expanded-interpretation-wrapper__1GQn_ .textarea-wrapper textarea.ant-input {
        height: 225px !important;
        max-height: 225px !important;
        overflow-y: scroll !important; }

.Therapies_therapies-wrapper__39OQc {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  min-height: 400px;
  padding: 0 30px;
  flex-direction: column;
  justify-content: left;
  align-items: center; }
  .Therapies_therapies-wrapper__39OQc .therapies-wrapper-header {
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #424d55; }
  .Therapies_therapies-wrapper__39OQc .therapy-name-col {
    width: 243px; }
  .Therapies_therapies-wrapper__39OQc .therapy-info-col {
    flex: 1 1; }
  .Therapies_therapies-wrapper__39OQc .therapy-wrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-top: 28px; }
    .Therapies_therapies-wrapper__39OQc .therapy-wrapper .therapy-drug-name {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #424d55; }
    .Therapies_therapies-wrapper__39OQc .therapy-wrapper textarea {
      resize: none !important;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #424d55; }
      .Therapies_therapies-wrapper__39OQc .therapy-wrapper textarea.ant-input {
        font-size: 14px;
        line-height: 18px;
        max-height: 225px !important;
        overflow-y: scroll !important;
        padding-right: 26px;
        padding-bottom: 26px; }
    .Therapies_therapies-wrapper__39OQc .therapy-wrapper .icon-saved {
      position: absolute;
      z-index: 9999;
      right: 4px;
      bottom: 5px; }
      .Therapies_therapies-wrapper__39OQc .therapy-wrapper .icon-saved .ok-icon {
        width: 100%;
        height: 100%;
        display: none;
        justify-content: center;
        align-items: center; }
        .Therapies_therapies-wrapper__39OQc .therapy-wrapper .icon-saved .ok-icon svg {
          width: 22px;
          height: 15px; }
          .Therapies_therapies-wrapper__39OQc .therapy-wrapper .icon-saved .ok-icon svg path {
            stroke: #22b36e; }
        .Therapies_therapies-wrapper__39OQc .therapy-wrapper .icon-saved .ok-icon.active {
          display: flex; }

.ClinicalTrials_new-table__1eIqU .ant-table-fixed {
  table-layout: fixed !important; }

.ClinicalTrials_new-table__1eIqU .ant-table-empty .ant-table-body {
  overflow-y: hidden !important; }

.ClinicalTrials_new-table__1eIqU .ant-table-thead > tr > th {
  height: 64px;
  background-color: #f6f6f6;
  font-size: 14px !important;
  padding: 15px; }
  .ClinicalTrials_new-table__1eIqU .ant-table-thead > tr > th:first-child {
    padding-left: 30px; }
  .ClinicalTrials_new-table__1eIqU .ant-table-thead > tr > th:last-child {
    padding-right: 30px; }

.ClinicalTrials_new-table__1eIqU .ant-table-tbody > tr > td {
  padding: 10px 0; }
  .ClinicalTrials_new-table__1eIqU .ant-table-tbody > tr > td:first-child {
    padding-left: 15px; }
  .ClinicalTrials_new-table__1eIqU .ant-table-tbody > tr > td:last-child {
    padding-right: 15px; }

.ClinicalTrials_new-table__1eIqU .multiLine-text {
  height: 80%;
  font-size: 14px !important; }

.ClinicalTrials_new-table__1eIqU .label-tag .label-text {
  font-size: 12px;
  font-weight: bold;
  color: #424d55; }

.ClinicalTrials_new-table__1eIqU .n-table-cell {
  padding: 0 15px;
  font-size: 14px; }

.ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N {
  width: 100%;
  height: 60vh;
  overflow: hidden !important; }
  .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .actionable-details-done-wrapper {
    display: flex;
    position: absolute;
    bottom: 50px;
    width: 100%;
    justify-content: left;
    text-align: center; }
    .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .actionable-details-done-wrapper .actionable-details-done {
      display: flex;
      justify-content: center;
      font-style: normal;
      font-size: 18px;
      text-align: center;
      color: #96a2aa; }
  .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .add-actionable-details-container-button-text {
    display: flex;
    width: 100%;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #fff; }
  .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .ant-btn {
    background-color: white !important; }
  .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .actionable-details-container-title {
    text-align: left;
    font-weight: bold;
    font-size: 22px;
    color: #424d55; }
  .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .ant-tabs {
    height: inherit;
    text-align: left;
    overflow: scroll !important; }
    .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .ant-tabs .ant-tabs-bar {
      padding: 0 30px;
      border-bottom: none; }
    .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .ant-tabs .ant-tabs-nav-container {
      border-bottom: 1px solid rgba(0, 0, 0, 0.0984771); }
    .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .ant-tabs .ant-tabs-ink-bar-animated {
      height: 2px !important; }
    .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .ant-tabs .ant-tabs-tab {
      padding: 27px 0 15px 0 !important;
      margin: 0 40px 0 0 !important; }
    .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .ant-tabs .ant-tabs-extra-content {
      -webkit-transition: 0.4s ease 0s;
      transition: 0.4s ease 0s; }
    .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .ant-tabs .ant-tabs-extra-content {
      margin: 10px 0 0 20px; }
  .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .ant-tabs .ant-tabs-top-content,
  .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .ant-tabs .ant-tabs-bottom-content {
    height: 300px; }
  .ActionableDetailsContainer_actionable-details-container-wrapper__3fp-N .empty-state {
    height: 177px; }

.FinalReportVariantsTable_new-table__2tO0u .ant-table-fixed {
  table-layout: fixed !important; }

.FinalReportVariantsTable_new-table__2tO0u .ant-table-empty .ant-table-body {
  overflow-y: hidden !important; }

.FinalReportVariantsTable_new-table__2tO0u .ant-table-thead > tr > th {
  height: 64px;
  background-color: #f6f6f6;
  font-size: 14px !important;
  padding: 15px; }

.FinalReportVariantsTable_new-table__2tO0u .multiLine-text {
  height: 80%;
  font-size: 14px !important; }

.FinalReportVariantsTable_new-table__2tO0u .label-tag .label-text {
  font-size: 12px;
  font-weight: bold;
  color: #424d55; }

.FinalReportVariantsTable_new-table__2tO0u .ok-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .FinalReportVariantsTable_new-table__2tO0u .ok-icon svg {
    width: 18px;
    height: 11px; }
    .FinalReportVariantsTable_new-table__2tO0u .ok-icon svg path {
      stroke: #424d55; }

.FinalReportVariantsTable_new-table__2tO0u .delete-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer; }
  .FinalReportVariantsTable_new-table__2tO0u .delete-icon svg {
    width: 15px;
    height: 15px; }
    .FinalReportVariantsTable_new-table__2tO0u .delete-icon svg:hover {
      border-radius: 4px; }
      .FinalReportVariantsTable_new-table__2tO0u .delete-icon svg:hover g path {
        fill: #424d55; }

.FinalReportVariantsTable_new-table__2tO0u .n-table-cell {
  padding: 0 15px;
  font-size: 14px; }

.FinalReportVariantsTable_new-table__2tO0u .selection-cell .ant-checkbox-inner {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px; }

.Search_search-wrapper__19rjZ {
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center; }
  .Search_search-wrapper__19rjZ .search-field {
    width: 100%; }
    .Search_search-wrapper__19rjZ .search-field .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered {
      padding: 0; }
      .Search_search-wrapper__19rjZ .search-field .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered .ant-input.ant-select-search__field {
        width: 100%;
        padding: 0 30px;
        border-radius: 32px;
        background: #e7eef2;
        outline-offset: 0 !important;
        outline: none !important;
        border: none; }
    .Search_search-wrapper__19rjZ .search-field .ant-select-selection__placeholder {
      width: 90%;
      height: 100%;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      background: none;
      z-index: 2;
      padding-left: 18px; }
  .Search_search-wrapper__19rjZ .search-field:focus {
    border: none; }
  .Search_search-wrapper__19rjZ input:focus {
    outline-offset: 0 !important;
    outline: none !important; }
  .Search_search-wrapper__19rjZ .search-icon {
    background: #e7eef2;
    border-radius: 50%;
    padding: 4px; }
  .Search_search-wrapper__19rjZ .anticon.anticon-search {
    position: absolute;
    left: 17px;
    z-index: 2; }
  .Search_search-wrapper__19rjZ .clear-search-button {
    position: relative;
    right: 30px;
    z-index: 2;
    width: 16px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0);
    background-position: center;
    background-size: contain;
    cursor: pointer;
    border: 0;
    margin-left: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none; }
    .Search_search-wrapper__19rjZ .clear-search-button:active, .Search_search-wrapper__19rjZ .clear-search-button:focus {
      outline: none; }
    .Search_search-wrapper__19rjZ .clear-search-button::-moz-focus-inner {
      border: 0; }
  .Search_search-wrapper__19rjZ .anticon-close svg {
    color: #96a2aa; }
  .Search_search-wrapper__19rjZ .ant-input {
    height: 26px; }

.FinalReportTableToolBar_toolbar-wrapper__1R-d2 {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  background-color: #fff;
  font-style: normal;
  font-size: 14px;
  padding: 10px 0px;
  z-index: 2; }
  .FinalReportTableToolBar_toolbar-wrapper__1R-d2 .left-wrapper {
    display: flex;
    position: relative;
    justify-content: left;
    align-items: center; }
    .FinalReportTableToolBar_toolbar-wrapper__1R-d2 .left-wrapper .search-field-wrapper {
      width: 273px;
      min-width: 273px;
      max-width: 373px;
      display: flex;
      position: relative;
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease; }
      .FinalReportTableToolBar_toolbar-wrapper__1R-d2 .left-wrapper .search-field-wrapper .ant-input {
        background: #f6f6f6 !important;
        border-radius: 4px !important; }
      .FinalReportTableToolBar_toolbar-wrapper__1R-d2 .left-wrapper .search-field-wrapper .anticon.anticon-search {
        left: 11px; }
  .FinalReportTableToolBar_toolbar-wrapper__1R-d2 .search-field:focus {
    border: none; }
  .FinalReportTableToolBar_toolbar-wrapper__1R-d2 input:focus {
    outline-offset: 0px !important;
    outline: none !important; }
  .FinalReportTableToolBar_toolbar-wrapper__1R-d2 .search-icon {
    background: #e7eef2;
    border-radius: 50%;
    padding: 4px; }
  .FinalReportTableToolBar_toolbar-wrapper__1R-d2 .anticon-close svg {
    color: #96a2aa; }
  .FinalReportTableToolBar_toolbar-wrapper__1R-d2 .ant-input {
    height: 26px; }

.FinalReportActionableAlterations_final-report-actionable-alterations__2bxMo .select-variants-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 33px 24px; }

.FinalReportActionableAlterations_final-report-actionable-alterations__2bxMo .select-variants-btn {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 32px;
  width: 141px;
  font-weight: 600;
  font-size: 12px !important;
  text-align: center;
  text-transform: uppercase;
  color: #fff; }
  .FinalReportActionableAlterations_final-report-actionable-alterations__2bxMo .select-variants-btn:hover {
    color: #fff; }

.FinalReportActionableAlterations_final-report-actionable-alterations__2bxMo .final-report-teble-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  padding: 0 24px; }

.FinalReportActionableAlterations_final-report-actionable-alterations__2bxMo .apply-table-btn {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 32px;
  width: 205px;
  font-weight: 600;
  font-size: 12px !important;
  text-align: center;
  text-transform: uppercase;
  color: #fff; }
  .FinalReportActionableAlterations_final-report-actionable-alterations__2bxMo .apply-table-btn:hover {
    color: #fff; }

.FinalReportClinicalTable_new-table__3c5Kz .ant-table-fixed {
  table-layout: fixed !important; }

.FinalReportClinicalTable_new-table__3c5Kz .ant-table-empty .ant-table-body {
  overflow-y: hidden !important; }

.FinalReportClinicalTable_new-table__3c5Kz .ant-table-thead > tr > th {
  height: 64px;
  background-color: #f6f6f6;
  font-size: 14px !important;
  padding: 15px; }

.FinalReportClinicalTable_new-table__3c5Kz .multiLine-text {
  height: 80%;
  font-size: 14px !important; }

.FinalReportClinicalTable_new-table__3c5Kz .label-tag .label-text {
  font-size: 12px;
  font-weight: bold;
  color: #424d55; }

.FinalReportClinicalTable_new-table__3c5Kz .ok-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .FinalReportClinicalTable_new-table__3c5Kz .ok-icon svg {
    width: 18px;
    height: 11px; }
    .FinalReportClinicalTable_new-table__3c5Kz .ok-icon svg path {
      stroke: #424d55; }

.FinalReportClinicalTable_new-table__3c5Kz .delete-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer; }
  .FinalReportClinicalTable_new-table__3c5Kz .delete-icon svg {
    width: 15px;
    height: 15px; }
    .FinalReportClinicalTable_new-table__3c5Kz .delete-icon svg:hover {
      border-radius: 4px; }
      .FinalReportClinicalTable_new-table__3c5Kz .delete-icon svg:hover g path {
        fill: #424d55; }

.FinalReportClinicalTable_new-table__3c5Kz .n-table-cell {
  padding: 0 15px;
  font-size: 14px; }

.FinalReportUncertainClinicalSignificance_final-report-uncertain-clinical-significance__23mp5 .select-variants-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 33px 24px; }

.FinalReportUncertainClinicalSignificance_final-report-uncertain-clinical-significance__23mp5 .select-variants-btn {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 32px;
  width: 141px;
  font-weight: 600;
  font-size: 12px !important;
  text-align: center;
  text-transform: uppercase;
  color: #fff; }
  .FinalReportUncertainClinicalSignificance_final-report-uncertain-clinical-significance__23mp5 .select-variants-btn:hover {
    color: #fff; }

.FinalReportUncertainClinicalSignificance_final-report-uncertain-clinical-significance__23mp5 .final-report-teble-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  padding: 0 24px; }

.FinalReportUncertainClinicalSignificance_final-report-uncertain-clinical-significance__23mp5 .apply-table-btn {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 32px;
  width: 315px;
  font-weight: 600;
  font-size: 12px !important;
  text-align: center;
  text-transform: uppercase;
  color: #fff; }
  .FinalReportUncertainClinicalSignificance_final-report-uncertain-clinical-significance__23mp5 .apply-table-btn:hover {
    color: #fff; }

.FinalReportGenomeWideFindings_main-content__3mva7 {
  padding: 0 32px; }

.FinalReportGenomeWideFindings_main-content-row__2cGFl {
  display: flex;
  align-items: flex-end;
  color: #424d55;
  margin-bottom: 24px; }

.FinalReportGenomeWideFindings_main-content-row-label__2s5H1 {
  width: 320px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase; }

.FinalReportGenomeWideFindings_main-content-row-value__1S0sD {
  font-size: 14px;
  line-height: 18px; }

.FinalReportSidebar_final-report-sidebar__3I5ku {
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); }

.FinalReportSidebar_section__1qw6z {
  padding: 25px;
  cursor: pointer;
  -webkit-transition: all 0.15s;
  transition: all 0.15s; }
  .FinalReportSidebar_section__1qw6z:hover {
    background-color: rgba(33, 150, 243, 0.1); }

.FinalReportSidebar_section-selected__1ILEH {
  background-color: rgba(33, 150, 243, 0.1); }

.FinalReportSidebar_step-label__3wv6P {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #000; }

.FinalReportSidebar_section-title__3PIVR {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #222222;
  -webkit-transition: all 0.15s;
  transition: all 0.15s; }

.FinalReportSidebar_section-title-selected__1CtPg {
  color: #1386e3; }

.FinalReportSidebar_generate-btn-wrapper__3Gg3y {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 41px;
  margin-top: 10px; }
  .FinalReportSidebar_generate-btn-wrapper__3Gg3y button {
    width: 142px !important;
    height: 32px !important;
    padding: 0 !important; }

.Alert_alert__ETChP {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9999; }
  .Alert_alert__ETChP .wrapper {
    display: flex;
    background-color: white;
    min-width: 333px;
    min-height: 78px;
    box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow: hidden;
    position: relative; }
  .Alert_alert__ETChP .close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 18px;
    height: 18px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/static/media/close-big.e0f0c2bf.svg);
    opacity: 0.7;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .Alert_alert__ETChP .close:hover {
      opacity: 1; }
  .Alert_alert__ETChP .sign {
    width: 40px;
    background-position: center;
    background-color: #f2f6f8;
    background-repeat: no-repeat;
    background-size: 17px 17px; }
    .Alert_alert__ETChP .sign.error {
      background-image: url(/static/media/circle-x.0b91aba8.svg); }
    .Alert_alert__ETChP .sign.warning {
      background-image: url(/static/media/warning-sign.c94a4bed.svg); }
    .Alert_alert__ETChP .sign.success {
      background-image: url(/static/media/success-sign.a033fb37.svg); }
  .Alert_alert__ETChP .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1;
    padding: 20px 45px 20px 20px;
    max-width: 500px; }
  .Alert_alert__ETChP .title {
    font-weight: 600;
    font-size: 16px;
    color: #424d55; }
  .Alert_alert__ETChP .message {
    font-size: 16px;
    color: #424d55; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Source Sans Pro, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; }

code {
  font-family: Source Sans Pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px; }

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff; }

::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4; }

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff; }

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4; }

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none; }

button,
button:active,
button:focus {
  outline: none !important; }

/* div box */
.scrollbar {
  overflow-y: scroll; }
  .scrollbar.sidebar-closed {
    overflow-y: hidden; }

