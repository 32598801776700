@import "Css/Colors.scss";

.variant-conflict-pending-classification-item :global {
  display: flex;
  justify-content: flex-end;

  .conflict-icon {
    width: 29px;
    height: 29px;
    min-width: 29px;
    min-height: 29px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .pending-classification-wrapped {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 43px 0 0 43px;
    height: 43px;
    padding: 0 35px 0 16px;
    margin-top: 14px;

    cursor: pointer;

    .conflict-icon {
      margin-right: 12px;
    }

    .wrapped-conflict-text {
      display: flex;
      font-size: 16px;
      color: $title_color;
      white-space: nowrap;
    }

    span {
      margin-right: 10px;
    }
  }

  .pending-classification-unwrapped {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 30px 35px 30px 58px;
    background-color: $light-blue;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    position: relative;

    font-size: 18px;

    & > * {
      margin-right: 85px;
    }

    .conflict-title {
      font-weight: 600;
    }

    .conlict-wrap-btn {
      width: 10px;
      height: 6px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
      border: none;
      position: absolute;
      top: 50%;
      right: 35px;
      margin-right: 0;
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
