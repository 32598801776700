@import "Css/Colors.scss";

.table-source-description-wrapper :global {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;

  .table-source-description {
    display: flex;
    flex-direction: column;

    .source {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 4px;

      &.link {
        color: $brand_primary_color;
        cursor: pointer;
      }
    }

    .description {
      max-width: 750px;
      word-wrap: break-word;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $title_color;
    }
  }
}
