@import "Css/Colors.scss";

.search-wrapper :global {
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center;
  height: 28px;

  .ant-select {
    height: 28px;
  }

  .ant-select-selection.ant-select-selection--single {
    .ant-select-selection__rendered {
      padding: 0;
      background: $neutral_10;
      border-radius: 32px;
      height: 28px;

      .ant-input.ant-select-search__field {
        width: 100%;
        padding: 0 30px;
        border-radius: 32px;
        background: $neutral_10;
        outline-offset: 0 !important;
        outline: none !important;
        border: none;
      }

      .ant-input {
        height: 28px;
      }
    }
  }
  .ant-select-selection__placeholder {
    width: 90%;
    height: 100%;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    background: none;
    z-index: 2;
    padding-left: 23px;
  }

  .search-field:focus {
    border: none;
  }
  input:focus {
    outline-offset: 0 !important;
    outline: none !important;
  }
  .search-icon {
    background: $neutral_10;
    border-radius: 50%;
    padding: 4px;
  }
  .anticon.anticon-search {
    position: absolute;
    left: 17px;
    z-index: 2;
  }

  .clear-search-button {
    position: relative;
    right: 30px;
    z-index: 2;
    width: 16px;
    height: 16px;
    background-color: rgba(0, 0, 0, 0);
    background-position: center;
    background-size: contain;
    cursor: pointer;
    border: 0;
    margin-left: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;

    &:active,
    &:focus {
      outline: none;
    }
    &::-moz-focus-inner {
      border: 0;
    }
  }
  .anticon-close svg {
    color: $subtitle_color;
  }
  .ant-input {
    height: 26px;
  }
}
