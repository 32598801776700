@import "Css/Colors.scss";

.tab-pane-content-wrapper :global {
  width: 100%;
  height: auto;

  .title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $a-color-grey-n1;
  }

  .amount {
    font-weight: bold;
    font-size: 14px;
    color: $brand_primary_color;
  }
}
