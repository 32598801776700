@import "Css/Colors.scss";

.evidence-wrapper :global {
  width: 100%;
  overflow: hidden !important;

  .add-evidence-button-text {
    display: flex;
    width: 100%;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: $white;
    height: 32px !important;
    padding: 0 10px !important;
  }

  .section-toolbar {
    height: 41px;
    display: flex;
    align-items: center;
    padding: 0 60px;

    .evidence-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
    }

    .toolbar-select {
      width: 200px;
      margin-left: 10px;

      .ant-select {
        width: 100%;
      }
    }
  }

  .ant-tabs {
    text-align: left;

    .ant-tabs-ink-bar-animated {
      height: 2px !important;
    }

    .ant-tabs-tab {
      padding: 27px 0 15px 0 !important;
      margin: 0 60px 0 0 !important;
    }

    .ant-tabs-bar {
      padding: 0 60px 0 50px;
      border-bottom: none;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 60px;
        right: 60px;
        height: 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.0984771);
      }
    }
    .ant-tabs-extra-content {
      transition: 0.4s ease 0s;
    }
    .ant-tabs-extra-content {
      margin: 10px 0 0 20px;
    }

    .ant-tabs-tabpane {
      overflow: auto;
    }
  }
  .ant-tabs .ant-tabs-top-content,
  .ant-tabs .ant-tabs-bottom-content {
    height: 300px;
  }
  .empty-state {
    height: 177px;
  }
}
