@import "Css/Colors.scss";

.header-wrapper :global {
  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 48px;
    background-color: $title_color;
    font-style: normal;
    font-size: 16px;
    text-align: left;
  }

  .left-wrapper {
    margin-left: 2em;

    .go-back-button {
      display: flex;
      width: 110px;
      height: 33px;
      justify-content: center;
      align-items: center;
      background-color: $brand_primary_color;
      border-radius: 5px;
      cursor: pointer;

      .go-back-button-text {
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        color: $white;
      }

      &:hover {
        background-color: $brand_primary_hover_color;
      }
    }

    .lock-img {
      width: 24px;
      height: 24px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .right-side-item {
    width: auto;
    margin-right: 20px;
  }

  .header-info-panel {
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease-in-out;

    &.out {
      max-height: 1000px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      z-index: 5;
    }
  }
}
