.login-wrapper :global {
  .ant-form.ant-form-vertical {
    position: fixed;
    top: 40%;
    padding: 48px 64px 30px 64px;
    background: #fff;
    .ant-row.ant-form-item {
      width: 464px;
      margin-bottom: 18px;
    }
    .form-item-title {
      font-weight: 600;
      font-size: 14px;
      text-align: left;
      margin-bottom: 5px;
    }
  }
}
