@import "Css/Colors.scss";

.wrapper {
  background-color: $final-report-bg-color;
  width: 100vw;
  height: 100vh;
  padding: 32px 40px;
  overflow: auto;
}

.row {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  padding: 24px 32px;
}

.row-section {
}

.row-section-gsid {
  width: 285px;
}

.row-section-panel-type {
  width: 285px;
}

.row-section-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-section-title {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: $a-color-grey-n1;

  margin-bottom: 27px;
}

.row-section-value {
  font-size: 14px;
  line-height: 18px;

  color: $a-color-black-n60;
}

.link {
  display: flex;
  align-items: center;
}

.link-icon {
  width: 24px;
  height: 24px;

  border-radius: 50%;
  border: 3px solid $a-color-grey-n4;
  margin-right: 16px;
}
.link-icon-locked {
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.link-link {
  font-size: 14px;
  line-height: 18px;
  color: $a-color-grey-n1;
  text-decoration: underline;

  &:hover {
    color: $a-color-grey-n1;
  }
}

.link-div-line {
  width: 120px;
  height: 0px;
  border-top: 2px dashed $a-color-evidance-bp7;
  margin: 0 24px;
}
