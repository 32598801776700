@import "Css/Colors.scss";

.therapies-wrapper :global {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  min-height: 400px;
  padding: 0 30px;

  flex-direction: column;
  justify-content: left;
  align-items: center;

  .therapies-wrapper-header {
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $title_color;
  }

  .therapy-name-col {
    width: 243px;
  }
  .therapy-info-col {
    flex: 1;
  }

  .therapy-wrapper {
    display: flex;
    align-items: flex-start;

    position: relative;
    margin-top: 28px;

    .therapy-drug-name {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $title_color;
    }

    textarea {
      resize: none !important;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: $title_color;

      &.ant-input {
        font-size: 14px;
        line-height: 18px;
        max-height: 225px !important;
        overflow-y: scroll !important;
        padding-right: 26px;
        padding-bottom: 26px;
      }
    }

    .icon-saved {
      position: absolute;
      z-index: 9999;
      right: 4px;
      bottom: 5px;

      .ok-icon {
        width: 100%;
        height: 100%;
        display: none;
        justify-content: center;
        align-items: center;

        svg {
          width: 22px;
          height: 15px;

          path {
            stroke: $panel-filled;
          }
        }

        &.active {
          display: flex;
        }
      }
    }
  }
}
