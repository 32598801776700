@import "Css/Colors.scss";

.new-table :global {
  .ant-table-fixed {
    table-layout: fixed !important;
  }

  .ant-table-empty {
    .ant-table-body {
      overflow-y: hidden !important;
    }
  }

  .ant-table-thead > tr > th {
    height: 64px;
    background-color: $a-color-grey-n5;
    font-size: 14px !important;
    padding: 15px;

    &:first-child {
      padding-left: 30px;
    }
    &:last-child {
      padding-right: 30px;
    }
  }

  .ant-table-tbody > tr > td {
    padding: 10px 0;

    &:first-child {
      padding-left: 15px;
    }
    &:last-child {
      padding-right: 15px;
    }
  }

  .multiLine-text {
    height: 80%;
    font-size: 14px !important;
  }

  .label-tag {
    .label-text {
      font-size: 12px;
      font-weight: bold;
      color: $title_color;
    }
  }

  .n-table-cell {
    padding: 0 15px;
    font-size: 14px;
  }
}
