@import "Css/Colors.scss";

.actionable-details-container-wrapper :global {
  width: 100%;
  height: 60vh;
  overflow: hidden !important;

  .actionable-details-done-wrapper {
    display: flex;
    position: absolute;
    bottom: 50px;
    width: 100%;
    justify-content: left;
    text-align: center;

    .actionable-details-done {
      display: flex;
      justify-content: center;
      font-style: normal;
      font-size: 18px;
      text-align: center;
      color: $neutral_30;
    }
  }

  .add-actionable-details-container-button-text {
    display: flex;
    width: 100%;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: $white;
  }
  .ant-btn {
    background-color: white !important;
  }

  .actionable-details-container-title {
    text-align: left;
    font-weight: bold;
    font-size: 22px;
    color: $title_color;
  }

  .ant-tabs {
    height: inherit;
    text-align: left;
    overflow: scroll !important;

    .ant-tabs-bar {
      padding: 0 30px;
      border-bottom: none;
    }

    .ant-tabs-nav-container {
      border-bottom: 1px solid rgba(0, 0, 0, 0.0984771);
    }

    .ant-tabs-ink-bar-animated {
      height: 2px !important;
    }

    .ant-tabs-tab {
      padding: 27px 0 15px 0 !important;
      margin: 0 40px 0 0 !important;
    }
    .ant-tabs-extra-content {
      transition: 0.4s ease 0s;
    }
    .ant-tabs-extra-content {
      margin: 10px 0 0 20px;
    }
  }
  .ant-tabs .ant-tabs-top-content,
  .ant-tabs .ant-tabs-bottom-content {
    height: 300px;
  }
  .empty-state {
    height: 177px;
  }
}
