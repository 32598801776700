@import "Css/Colors.scss";
$bg-img-sort-arrows: url("../assets/sort-arrows.png");
$bg-img-arrow-down: url("../assets/arrowDown.svg");

.toolbar-wrapper :global {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  background-color: $white;
  font-style: normal;
  font-size: 14px;
  padding: 10px 25px;
  z-index: 2;
  border-bottom: $border_color solid 1px;

  .left-wrapper {
    display: flex;
    position: relative;
    justify-content: left;
    align-items: center;

    .mutation-select-wrapper {
      display: block;
      font-weight: 600;
      font-size: 14px;
      margin-right: 6px;
    }
    .search-field-wrapper {
      width: 345px;
      min-width: 240px;
      max-width: 345px;
      display: flex;
      position: relative;
      transition: 0.4s ease;

      &.sidebar-open {
        transition: 0.4s ease;
        width: 240px;
      }
    }
  }
  .right-wrapper {
    display: flex;
    align-items: center;

    // transform: translate(0px, 0px);
    transition: 0.4s ease;

    &.sidebar-open {
      // transform: translate(-175px, 0px);
    }
  }
  .search-field:focus {
    border: none;
  }
  input:focus {
    outline-offset: 0px !important;
    outline: none !important;
  }
  .search-icon {
    background: $neutral_10;
    border-radius: 50%;
    padding: 4px;
  }
  .anticon-close svg {
    color: $subtitle_color;
  }
  .ant-input {
    height: 26px;
  }

  .confirmation-button {
    transition: all 0.25s;
    margin-left: auto;
    width: 195px;
    height: 28px;
    border-radius: 4px;
    background-image: url("../../../../assets/mail-grey.svg");
    background-position: 12px center;
    background-repeat: no-repeat;
    padding-left: 35px;
    border: none;

    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    color: $title_color;
    &:enabled {
      cursor: pointer;
    }
    &:active,
    &:focus {
      outline: none;
    }
    &::-moz-focus-inner {
      border: 0;
    }

    &:hover:enabled,
    &:active:enabled {
      color: white;
      background-image: url("../../../../assets/mail-white.svg");
      background-color: $brand_primary_color;
    }
  }
  .toolbar-divider-line {
    height: 18px;
    width: 1px;
    background: $border_color;
    margin-left: 5px;
    margin-right: 5px;
  }
  .sort-wrapper {
    margin-right: 12px;
    .ant-select {
      min-width: 130px;
      max-height: 28px;
      .anticon.anticon-down.ant-select-arrow-icon {
        width: 20px;
        height: 20px;
        background-image: url("../../../../assets/sort-arrows.png");
        svg {
          display: none !important;
        }
      }
      .ant-select-selection {
        border: none;
        transition: none;
      }
      .ant-select-selection--single {
        height: 28px;
        .ant-select-selection__rendered {
          line-height: unset !important;
          padding-top: 2px;
          .ant-select-selection-selected-value {
            padding-right: 0 !important;
            color: #424d55;
          }
          .ant-select-selection__placeholder {
            top: 13px;
            right: 26px;
            left: unset;
            color: #424d55;
            font-weight: 600;
          }
        }
        .ant-select-arrow {
          top: 14px;
          right: 70px;
        }
      }
      .ant-select-selection-selected-value {
        float: right;
      }
    }

    border-radius: 4px;
    & :hover {
      background: $neutral_10;
      border-radius: 4px;
    }
  }
  .filter-wrapper {
    margin-right: 12px;
    .ant-select {
      min-width: 120px;
      max-height: 28px;
      .ant-select-arrow {
        width: 12px;
        height: 12px;
      }
      .anticon.anticon-down.ant-select-arrow-icon {
        width: 10px;
        height: 10px;
        background-image: url("../../../../assets/arrowDown.svg");
        background-repeat: no-repeat;
        svg {
          display: none !important;
        }
      }
      .ant-select-selection {
        border: none;
        transition: none;
      }
      .ant-select-selection--single {
        height: 28px;
        .ant-select-selection__rendered {
          line-height: unset !important;
          padding-top: 2px;
          .ant-select-selection-selected-value {
            padding-right: 0 !important;
            color: #424d55;
          }
          .ant-select-selection__placeholder {
            top: 13px;
            right: 26px;
            left: unset;
            color: #424d55;
            font-weight: 600;
          }
        }
        .ant-select-arrow {
          top: 16px;
          right: 78px;
        }
      }
      .ant-select-selection-selected-value {
        float: right;
      }
    }

    border-radius: 4px;
    & :hover {
      background: $neutral_10;
      border-radius: 4px;
    }
  }
}
