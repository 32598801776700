@import "Css/Colors.scss";

.info-panel-wrapper :global {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
  background-color: white;

  font-style: normal;
  font-size: 14px;
  padding: 0px 55px 16px;

  .panel-type-wrapper {
    display: flex;
    align-items: center;
    margin-right: 80px;
    padding-top: 16px;
  }

  .sample-type-wrapper {
    display: flex;
    align-items: center;
    margin-right: 80px;
    padding-top: 16px;
  }

  .tumor-info-wrapper {
    display: flex;
    align-items: center;
    margin-right: 80px;
    padding-top: 16px;
  }

  .patient-wrapper {
    display: flex;
    align-items: center;
    padding-top: 16px;
  }

  .info-panel-label {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $a-color-black-n60;

    margin-right: 12px;
    white-space: nowrap;
  }

  .info-panel-value {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $a-color-grey-n1;
  }

  .info-panel-select {
    height: 32px;

    .ant-select {
      height: 32px;

      .ant-select-selection-selected-value {
        line-height: 31px;

        .label-text {
          font-size: 14px;
          color: $a-color-grey-n1;
        }
      }
    }
  }

  .tumor-info-location {
    margin-left: 16px;
  }

  .patient-age {
    margin-left: 16px;
  }
}
