@import "Css/Colors.scss";

.main-page :global {
  display: flex;
  justify-content: flex-start;
  z-index: 0;
  width: 100%;
  // height: 100vh;

  .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    margin-right: 11px;
  }
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .main-content-wrapper {
    background-color: $main-bg-color;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    z-index: 0;
    transition: 0.4s ease;
    transform: translate(-155px, 0px);

    &.sidebar-open {
      transition: 0.4s ease;
      transform: translate(0px, 0px);
    }

    .tumor-toolbar-collapse {
      display: flex;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      transition: all 0.4s ease-in-out;

      &.out {
        max-height: 1000px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        z-index: 5;
      }
    }

    .toolbar-collapse {
      display: flex;
      width: 100%;
      z-index: 2;

      &.shadow {
        border-bottom: none;
        box-shadow: 0 2px 3px 1px #dae1e680;
      }
    }
  }

  .sidebar-wrapper {
    display: flex;
    //width: 192px;
    height: 100%;
    z-index: 2;
    transition: 0.4s ease;
    transform: translate(-155px, 0px);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.sidebar-open {
      transition: 0.4s ease;
      transform: translate(0px, 0px);
    }
  }
}
