@import "Css/Colors.scss";

.variant-conflicts-used-notifications :global {
  .variant-conflicts-used-item {
    display: flex;
    align-items: center;
  }

  .conflict-icon {
    width: 29px;
    height: 29px;
    min-width: 29px;
    min-height: 29px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .conflict-message {
    font-size: 14px;
    color: black;
    margin-left: 5px;
    margin-right: 5px;
    text-align: left;

    span {
      font-weight: 600;
    }
  }
}
