@import "Css/Colors.scss";

.classification-history-wrapper :global {
  width: 100%;
  height: auto;
  overflow: hidden !important;

  .empty-state {
    height: 177px;
  }
  .ant-table-wrapper {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    width: 100%;

    &.classification-history-table-wrapper {
      .ant-table-content {
        //max-height: 300px !important;
        .ant-table-scroll {
          overflow: hidden !important;
          -webkit-transition: all 0.4s ease;
          -moz-transition: all 0.4s ease;
          transition: all 0.4s ease;

          .ant-table-body {
            max-height: 255px !important;
            overflow-y: scroll !important;
            overflow-x: hidden !important;
          }
        }
      }
      &.with-scroll {
        height: 300px;
        max-height: 300px !important;
        box-shadow: inset 0 -91px 40px -110px rgba(0, 0, 0, 0.17);
      }
    }
    .with-scroll {
      overflow-y: scroll !important;
    }
  }

  .ant-table-thead > tr > th {
    background-color: white;
    color: $a-color-black-n30;
    font-weight: bold;
    font-size: 12px !important;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding: 10px 15px;
  }

  .n-table-cell {
    padding: 10px 15px;
    font-size: 14px;
  }

  .see-all-btn {
    display: flex;
    justify-content: center;

    & > div {
      width: 100px;

      .label {
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
      }

      .icon {
        display: none;
      }
    }
  }
}
