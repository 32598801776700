.confirmation-page-wrapper :global {
  height: calc(100vh - 48px);

  .confirmation-content {
    height: 100%;

    .confirmation-table {
      height: 100%;
    }
  }
}
