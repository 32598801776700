@import "Css/Colors.scss";

.external-resources-wrapper :global {
  .external-resources {
    width: 167px;
    padding-left: 6px;
    .external-resources-header {
      .external-resources-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: $title_color;
        margin: 14px 0 9px;
      }
      .divider {
        border-bottom: 1px solid $border_color;
      }
    }
    .external-resources-part {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .external-resources-part-title {
        margin: 12px 0 0 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: $subtitle_color;
      }

      ul {
        padding-left: 0;
        text-align: left;
        list-style: none;

        li {
          margin: 10px 0 0 0;
          a {
            font-weight: 600;
            font-size: 14px;
            color: $title_color;
            line-height: 18px;

            &:hover {
              color: $neutral_hover_30;
            }
          }
          .text-not-link {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;

            .text-not-link-title {
              flex: 3;
              font-weight: 600;
              font-size: 14px;
              color: $title_color;
              line-height: 18px;
            }

            .text-not-link-value {
              flex: 3 1;
              color: $neutral_hover_30;
              width: 20%;
              cursor: pointer;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              span {
                cursor: default;
              }
            }

            .external-resources-title {
              padding-right: 7px;
              font-weight: 600;
              font-size: 14px;
              color: $title_color;
              line-height: 18px;
            }

            .external-resources-array {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;

              a {
                margin-bottom: 6px;
              }
            }
          }
        }
      }
    }
  }
}
