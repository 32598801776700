@import "Css/Colors.scss";

.select-non-active-wrapper :global {
  display: flex;
  position: relative;
  .select-non-active {
    width: 120px;
    height: 60px;
    background: $a-color-grey-n5;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0px;
    border: 1px solid $a-color-black-n10;

    &.active {
      background-color: $brand_primary_color;
    }

    .select-non-active-title {
      margin-top: 10px;

      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
      color: $neutral_30;

      &.active {
        color: $white;
      }
    }

    .select-non-active-class {
      display: flex;
      justify-content: center;
      align-items: baseline;

      .label-custom-style {
        font-weight: 600;
        font-size: 22px;
        line-height: 100%;
        color: $neutral_30;

        &.active {
          color: $white;
        }
      }
      span {
        font-weight: 600;
        font-size: 22px;
        line-height: 100%;
        color: $neutral_30;

        &.active {
          color: $white;
        }
      }
      .label-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $neutral_30;
      }
    }
  }
  .select-non-active.active {
    .label-text {
      color: $white !important;
    }
  }
}
