@import "Css/Colors.scss";

.new-table :global {
  .ant-table-fixed {
    table-layout: fixed !important;
  }

  .ant-table-empty {
    .ant-table-body {
      overflow-y: hidden !important;
    }
  }

  .ant-table-thead > tr > th {
    height: 64px;
    background-color: $a-color-grey-n5;
    font-size: 14px !important;
    padding: 15px;
  }

  .multiLine-text {
    height: 80%;
    font-size: 14px !important;
  }

  .label-tag {
    .label-text {
      font-size: 12px;
      font-weight: bold;
      color: $title_color;
    }
  }

  .ok-icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 18px;
      height: 11px;

      path {
        stroke: $icon_color;
      }
    }
  }

  .delete-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    svg {
      width: 15px;
      height: 15px;

      &:hover {
        border-radius: 4px;
        g {
          path {
            fill: $input-border-color;
          }
        }
      }
    }
  }

  .n-table-cell {
    padding: 0 15px;
    font-size: 14px;
  }
}
