@import "Css/Colors.scss";

.zygosity-type-wrapper :global {
  display: flex;
  justify-content: right;
  flex-direction: column;
  width: 295px;
  height: 100%;
  padding-right: 55px;
  align-items: center;
  padding-top: 14px;
  cursor: pointer;

  .current-zygosity-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .title {
      font-size: 10px;
      line-height: 16px;
      color: $a-color-black-n30;
    }
    .context {
      font-size: 10px;
      line-height: 16px;
      color: $a-color-black-n30;
      padding-left: 5px;
    }
  }

  .zygosity-type-radio-group {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 8px;
    flex-direction: row;

    .reconfirm-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      width: 62%;
      height: 30px;
      .reconfirm-labeled-tag {
        font-size: 12px;
        font-weight: 600;
        text-align: left;
      }
      .reconfirm-text {
        font-size: 12px;
        width: 100%;
        text-align: left;
      }
    }

    .label-text {
      text-transform: uppercase;
    }
  }
}
