@import "Css/Colors.scss";

.expanded-interpretation-wrapper :global {
  display: flex;
  width: 100%;
  height: auto;
  overflow-y: scroll;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;

  .textarea-wrapper-label {
    font-size: 10px;
    line-height: 16px;

    color: $a-color-black-n30;
  }

  .textarea-wrapper {
    position: relative;
    min-width: 300px;
    min-height: 300px;
    width: 600px;
    margin: 0 26px 0 0;

    .icon-saved {
      position: absolute;
      z-index: 9999;
      right: 14px;
      bottom: 52px;

      .ok-icon {
        width: 100%;
        height: 100%;
        display: none;
        justify-content: center;
        align-items: center;

        svg {
          width: 28px;
          height: 22px;

          path {
            stroke: $panel-filled;
          }
        }

        &.active {
          display: flex;
        }
      }
    }

    textarea {
      resize: none !important;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $a-color-grey-n2;

      &.ant-input {
        height: 225px !important;
        max-height: 225px !important;
        overflow-y: scroll !important;
      }
    }
  }
}
