@import "Css/Colors.scss";

.zygosity-type-button-wrapper :global {
  .zygosity-type-button {
    position: relative;
    border-radius: 0px;
    background-color: $brand_primary_color !important;
    cursor: pointer;

    .select-title {
      display: block;
      position: absolute;
      right: 34%;
      top: 10px;
      font-weight: 300;
      font-size: 10px;
      line-height: 16px;
      color: $white;

      &.active {
        background: none !important;
        color: $neutral_30;
      }
    }
    .ant-select {
      .ant-select-arrow {
        & svg {
          transform: none !important;
        }

        &:hover {
          background: none;
        }
      }

      .ant-select-selection-selected-value {
        .reconfirm {
          display: none;
        }
      }
    }

    .select-header-text-style {
      color: $white !important;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;

      &.active {
        color: $neutral_30 !important;
      }
    }

    &.active {
      background: $a-color-grey-n5 !important;
      color: $neutral_30;

      svg path {
        fill: $neutral_30 !important;
      }
    }

    &.active {
      .ant-select-selection-selected-value {
        color: $neutral_30;
      }
    }
  }
}
