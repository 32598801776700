@import "Css/Colors.scss";

.variant-page-header-wrapper :global {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  transition: 0.4s ease;

  .left-data-wrapper {
    height: 100%;

    .left-data {
      display: grid;
      width: 100%;
      grid-template-columns: minmax(150px, 200px) minmax(150px, 200px) minmax(
          150px,
          200px
        );
      grid-auto-rows: minmax(auto, 48%);
      grid-gap: 5px;
      height: 100%;
      padding: 40px 5px 30px 40px;

      .gene {
        padding: 0 10px;

        &.gene-external-link {
          padding: 0 10px;
        }
      }
    }

    .cell {
      padding: 0 10px;

      &.cell-external-link {
        padding: 0 10px;

        .external-link-text {
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          color: $a-color-black-n60;
          text-transform: uppercase;
        }
      }
    }
  }

  .right-data {
    flex: 1 1 300px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    transform: translate(0px, 0px);
    transition: 0.4s ease;

    .right-data-conflict-message {
      margin-top: 10px;
    }

    &.sidebar-open {
      transform: translate(-175px, 0px);
    }
  }
}
