@import "Css/Colors.scss";
$bg-img-sort-arrows: url("../assets/sort-arrows.png");
$bg-img-arrow-down: url("../assets/arrowDown.svg");

.toolbar-wrapper :global {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  background-color: $white;
  font-style: normal;
  font-size: 14px;
  padding: 10px 0px;
  z-index: 2;

  .left-wrapper {
    display: flex;
    position: relative;
    justify-content: left;
    align-items: center;

    .search-field-wrapper {
      width: 273px;
      min-width: 273px;
      max-width: 373px;
      display: flex;
      position: relative;
      transition: 0.4s ease;

      .ant-input {
        background: $a-color-grey-n5 !important;
        border-radius: 4px !important;
      }
      .anticon.anticon-search {
        left: 11px;
      }
    }
  }

  .search-field:focus {
    border: none;
  }
  input:focus {
    outline-offset: 0px !important;
    outline: none !important;
  }
  .search-icon {
    background: $neutral_10;
    border-radius: 50%;
    padding: 4px;
  }
  .anticon-close svg {
    color: $subtitle_color;
  }
  .ant-input {
    height: 26px;
  }
}
