@import "Css/Colors.scss";

.variant-page-wrapper :global {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  z-index: 0;
  width: 100%;
  height: calc(100vh - 48px);

  .links-wrapper {
    display: flex;
    height: 100%;
    z-index: 2;
    transition: 0.4s ease;
    transform: translate(-155px, 0px);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    &.links-wrapper-open {
      transition: 0.4s ease;
      transform: translate(0px, 0px);
    }
  }

  .main-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    height: 100%;
    z-index: 0;
    transition: 0.4s ease;
    transform: translate(-155px, 0px);
    overflow-y: auto;

    &.links-wrapper-open {
      transition: 0.4s ease;
      transform: translate(0px, 0px);
    }
    .main-header-data {
      display: flex;
      height: 120px;
      background-color: $white;
      box-shadow: 0 2px 3px 1px $border_color;
      z-index: 10;
    }

    .main-data {
      // display: flex;
      height: calc(100vh - 170px);
      justify-content: space-between;
      // flex-direction: column;

      .history {
        width: 100%;
        padding: 15px 60px;
        max-height: 455px;
        box-sizing: border-box;

        &.links-wrapper-open {
          width: calc(100% - 179px);
          transition: 0.4s ease-in;
        }
      }

      .evidence {
        width: 100%;
        max-height: 455px;
        overflow: hidden;
        box-sizing: border-box;
        transition: 0.3s ease-in;

        &.links-wrapper-open {
          width: calc(100% - 179px);
          transition: 0.4s ease-in;
        }
      }

      .conflict-notifications {
        width: calc(100vw - 25px);
        &.links-wrapper-open {
          width: calc(100vw - 196px);
          transition: 0.4s ease-in;
        }
      }
    }
  }
}

.section-toolbar :global {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .classification-history-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $title_color;
    text-transform: uppercase;
  }

  .toolbar-select {
    width: 200px;
    margin-left: 10px;

    .ant-select {
      width: 100%;
    }
  }
}
