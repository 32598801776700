@import "Css/Colors.scss";

.set-test-phenotype :global {
  background-color: white;
  padding: 48px;
  width: 422px;
  color: black;

  .set-test-phenotype-title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 21px;
  }

  .set-test-phenotype-text {
    font-size: 12px;
    line-height: 16px;
    opacity: 0.6;
    margin-bottom: 20px;
  }

  .set-test-phenotype-input-row {
    margin-bottom: 20px;
  }

  .set-test-phenotype-btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .set-test-phenotype-btn {
    text-transform: uppercase;
    font-size: 12px !important;
    font-weight: 600;
    width: 96px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &[disabled] {
      color: white;
      border: none;

      &:hover {
        color: white;
      }
    }
    &--link {
      color: $brand_primary_color;
      border: none;
    }
  }
}
