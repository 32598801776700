@import "Css/Colors.scss";

.simple-button-wrapper :global {
  button {
    width: 150px;
    height: 33px;

    background-color: $brand_primary_color;
    color: white;

    font-size: 12px !important;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active {
      background-color: $brand_primary_hover_color;
      color: white;
    }

    &.light {
      background-color: white;
      color: $btn-light-text-color;

      &:hover,
      &:focus,
      &:active {
        background-color: white;
        color: $btn-light-text-color;
      }
    }
  }

  .ant-btn {
    width: 100%;
    height: 100%;
  }
}
