@import "Css/Colors.scss";

.tumor-info-percent-wrapper :global {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-left: 25px;

  position: relative;
}

.slider :global {
  width: 146px;

  .ant-slider {
    .ant-slider-handle {
      display: none;
    }

    &:hover {
      .ant-slider-handle {
        display: block;
      }
    }

    .ant-slider-rail {
      background-color: rgba(0, 0, 0, 0.1);
      height: 4px;
      border-radius: 2px;
    }

    .ant-slider-track {
      background-color: #2196f3;
      height: 4px;
      border-radius: 2px;
    }
  }
}

.value {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $a-color-grey-n1;

  margin-right: 8px;
}

.title {
  position: absolute;
  left: 0;
  top: -20px;

  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.3);
}
