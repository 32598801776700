@import "Css/Colors.scss";

.edit-notes-wrapper :global {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  .divider {
    display: flex;
    margin: 0 auto;
    justify-content: flex-end;
    text-align: left;
    width: 95%;
    height: 2px;
    background: $brand_primary_color;

    .counter {
      display: block;
      position: absolute;
      top: -8px;
      font-weight: normal;
      font-size: 14px;
      color: $subtitle_color;
    }
  }

  textarea {
    resize: none !important;
    border: none !important;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: $title_color;

    &.ant-input {
      max-width: 86%;
      height: min-content;
    }

    &.ant-input:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
}
