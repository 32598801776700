@import "Css/Colors.scss";

.final-report-page-wrapper :global {
  height: calc(100vh - 48px);
  overflow: auto;
  background-color: $final-report-bg-color;

  .final-report-main-content {
    padding: 25px 40px;
  }

  .final-report-header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;
  }

  .final-report-back-link {
    margin-right: 26px;
    color: rgba(0, 0, 0, 0.6);
  }

  .final-report-main-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $title_color;
    margin-bottom: 0;
  }

  .final-report-body-content {
    display: flex;
    justify-content: space-between;
  }

  .final-report-sidebar {
    width: 320px;
    min-width: 320px;
    margin-left: 33px;
  }

  .final-report-section {
    width: calc(100% - 353px);
    // padding: 33px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: $white;
  }

  .final-report-section-title {
    padding: 33px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $brand_primary_color;
  }
}
